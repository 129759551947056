<template>
  <div v-if="showCreateSchemaPrice">
    <div class="row mt-3" >
      <div class="col-6">
        <label class="subTitle mb-3"><i class="bi bi-dash me-2"></i>Set Schema Price</label>
      </div>
      <div class="col-6 text-right">
        <div class="mb-3 form-check">
          <button type="button" class="btn btn-primary"  data-bs-toggle="modal" data-bs-target="#schemaPriceModal" @click="showCreateSchema = true">Create Schema Price</button>
          <button type="button" class="btn btn-secondary ms-3" @click="getSchemaPrices">Reset Schema Prices</button>
        </div>
      </div>
    </div>
    <div>
      <div class="row" v-if="schemaPrices != null && schemaPrices.length > 0">
        <div class="col-12">
          <label class="subTitle" style="font-size: 20px"> {{ showExistingSchema ? "Available " : "Created " }} Schemas:</label>
          <table class="table table-bordered">
            <thead>
              <tr>
                <th>Amount From</th>
                <th>Amount To</th>
                <th>Discount</th>
                <th>Sale Price</th>
                <th>Price Type</th>
                <th>Currency</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(price, index) in schemaPrices" :key="index">
                <td>{{price.amountFrom}}</td>
                <td>{{price.amountTo}}</td>
                <td>{{price.discount}}</td>
                <td>{{price.salePrice}}</td>
                <td>{{(priceTypes.filter(f => f.id == price.priceType)).map(m => m.name)[0]}}</td>
                <td>{{(currencies.filter(f => f.currencyId == price.currencyId)).map(m => m.name)[0]}}</td>
                <td class="text-center">
                  <button type="button" class="btn btn-secondary" @click="deleteSchemaPrice(price)">Delete</button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <div class="modal fade" id="schemaPriceModal" tabindex="-1" aria-labelledby="schemaPriceModal" aria-hidden="true">
      <div class="modal-dialog modal-xl">
        <div class="modal-content">
          <div class="modal-header">
            <label class="modal-title subTitle">Schema Price</label>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <div class="container-fluid">
              <div class="row" v-if="schemaPrices != null && schemaPrices.length > 0">
                <div class="col-12">
                  <label class="subTitle" style="font-size: 20px"> Available Schemas:</label>
                  <table class="table table-bordered">
                    <thead>
                      <tr>
                        <th>Amount From</th>
                        <th>Amount To</th>
                        <th>Discount</th>
                        <th>Sale Price</th>
                        <th>Price Type</th>
                        <th>Currency</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(price, index) in schemaPrices" :key="index">
                        <td>{{price.amountFrom}}</td>
                        <td>{{price.amountTo}}</td>
                        <td>{{price.discount}}</td>
                        <td>{{price.salePrice}}</td>
                        <td>{{(priceTypes.filter(f => f.id == price.priceType)).map(m => m.name)[0]}}</td>
                        <td>{{(currencies.filter(f => f.currencyId == price.currencyId)).map(m => m.name)[0]}}</td>
                        <td class="text-center">
                          <button type="button" class="btn btn-secondary" @click="deleteSchemaPrice(price)">Delete</button>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div v-if="schemaPriceList != null && schemaPriceList.length > 0">
                <div class="row" >
                  <div class="col-12">
                    <label class="subTitle" style="font-size: 20px">Created Schemas:</label>
                    <table class="table table-bordered">
                      <thead>
                        <tr>
                          <th>Amount From</th>
                          <th>Amount To</th>
                          <th>Discount</th>
                          <th>Sale Price</th>
                          <th>Price Type</th>
                          <th>Currency</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="(price) in schemaPriceList" :key="price.id">
                          <td>{{price.amountFrom}}</td>
                          <td>{{price.amountTo}}</td>
                          <td>{{price.discount}}</td>
                          <td>{{price.salePrice}}</td>
                          <td>{{(priceTypes.filter(f => f.id == price.priceType)).map(m => m.name)[0]}}</td>
                          <td>{{(currencies.filter(f => f.currencyId == price.currencyId)).map(m => m.name)[0]}}</td>
                          <td class="text-center"> 
                            <button type="button" class="btn btn-primary" @click="addSchemaPrice(price)">Edit</button>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-4">
                  <div class="mb-3">
                    <label class="form-label">Pricing Type <span class="text-danger">*</span></label>
                    <select class="form-select" aria-label="Choose Type" v-model="fixedSchemaPrice">
                      <option selected disabled :value="null">Choose Type</option>
                      <option :value="false">Brackets</option>
                      <option :value="true">Fixed Price</option>
                    </select>
                  </div>
                </div>
                <div class="col-4" v-if="fixedSchemaPrice != null && !fixedSchemaPrice">
                  <div class="mb-3">
                    <label class="form-label">Amount From: <span class="text-danger">*</span></label>
                    <input type="number" class="form-control" v-model="schemaPrice.amountFrom" />
                  </div>
                </div>
                <div class="col-4" v-if="fixedSchemaPrice != null && !fixedSchemaPrice">
                  <div class="mb-3">
                    <label class="form-label">Amount To: <span class="text-danger">*</span></label>
                    <input type="number" class="form-control" v-model="schemaPrice.amountTo" />
                  </div>
                </div>
                <div class="col-4">
                  <div class="mb-3">
                    <label class="form-label">Price Type <span class="text-danger">*</span></label>
                    <div class="d-inline-flex w-100">
                      <v-select :options="priceTypes == null ? [] : priceTypes" label="name" class="vselect w-100" placeholder="Choose Price Type" v-model="schemaPrice.priceType" :reduce="priceType => priceType.id" >
                        <template slot="option" slot-scope="option" >
                            <span class="pl-4">{{ option.name }}</span>
                        </template>
                      </v-select>
                      <button type="button" class="btn btn-secondary ms-1" @click="getPriceTypes('created', true)"><i class="bi bi-arrow-clockwise"></i></button>
                    </div>
                  </div>
                </div>
                <div class="col-4">
                  <div class="mb-3">
                    <label class="form-label">Currency <span class="text-danger">*</span></label>
                    <select class="form-select" aria-label="Choose Service" v-model="schemaPrice.currencyId" disabled>
                      <option selected disabled :value="null">Choose Currency</option>
                      <option v-for="currency in currencies" :value="currency.currencyId" :key="currency.currencyId" >{{currency.name}}</option>
                    </select>
                  </div>
                </div>
                <div class="col-4">
                  <div class="mb-3">
                    <label class="form-label">Sale Price: <span class="text-danger">*</span></label>
                    <input type="number" class="form-control" v-model="schemaPrice.salePrice" />
                  </div>
                </div>
                <div class="col-4">
                  <div class="mb-3">
                    <label class="form-label">Discount: <span class="text-danger">*</span></label>
                    <input type="number" class="form-control" v-model="schemaPrice.discount" />
                  </div>
                </div>
                <div class="col-12 text-end" v-if="fixedSchemaPrice != null && !fixedSchemaPrice">
                  <button type="button" class="btn btn-secondary"  @click="addSchemaPrice(null)"><i class="bi bi-plus-circle"></i></button>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" ref="closeSchemaPriceModal" data-bs-dismiss="modal" @click="showCreateSchema = true">Close</button>
            <button type="button" class="btn btn-primary" @click="createSchemaPrice">Create Schema Price</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import Http, { ENDPOINTS } from "../../request";
import {commonFunctions} from "../../helpers/CommonFunctions"
export default {
  components: {
    vSelect,
  },
  data() {
    return {
      checkIfEmpty: commonFunctions.checkIfEmpty,
      showCreateSchema: null,
      schemaPrice: {
        id: null,
        salePrice: null,
        amountFrom: null,
        amountTo: null,
        discount: null,
        priceType: null,
        currencyId: null
      },
      showExistingSchema: false,
      fixedSchemaPrice: null,
      existingSchemas: null,
      schemaPriceList: [],
      currencies: null,
      priceTypes: null,
      showCreateApplication: false,
      showCreateMenuItem: false,
      showSetSchool: false,
      showSetFileConfigurations: false,
      createdSchemaPrice: null,
      schemaPrices: [],
    }
  },
  props: {
    applicationType: String,
    selectedService: Number,
    showCreateSchemaPrice: Boolean,
    denomination: Object,
    service: Object,
  },
  watch: {
    showCreateSchemaPrice() {
      if (this.showCreateSchemaPrice) {
        this.getSchemaPrices()
      }
    },
    showCreateApplication() {
      this.$emit('changeShowCreateApplication', this.showCreateApplication)
    },
    showCreateMenuItem() {
      this.$emit('changeShowCreateMenuItem', this.showCreateMenuItem)
    },
    showSetSchool() {
      this.$emit('changeShowSetSchool', this.showSetSchool)
    },
    showSetFileConfigurations() {
      this.$emit('changeShowSetFileConfigurations', this.showSetFileConfigurations)
    },
    fixedSchemaPrice(){
      this.schemaPriceList = []
      this.schemaPrice.amountFrom = null
      this.schemaPrice.amountTo = null  
      this.schemaPrice.priceType = null
      this.schemaPrice.discount = null
      this.schemaPrice.salePrice = null
    },
    'denomination.flag'() {
        this.getSchemaPrices()
    }
  },
  created(){
    this.schemaPrice.currencyId = this.$store.state.server.id == 1 ? 422 : this.$store.state.server.id == 2 ? 784 : 840;
    this.getPriceTypes("created");
    this.getCurrencies("created");
  },
  methods: {
  
    getPriceTypes(source, forceGet) {
      if (source == 'created' && (forceGet || this.priceTypes == null || this.priceTypes.length == 0)) {
        Http()
          .get(ENDPOINTS.priceTypes, {
          })
          .then((response) => {
            if (response.status) {
              this.priceTypes = response.data;
              if (response.dialog != null && response.dialog.message != null) {
                this.$toast.success(response.dialog.message)
              }
            } else {
              if (response.dialog != null && response.dialog.message != null) {
                this.$toast.error(response.dialog.message)
              }
            }
          }).catch((e) => {
            setTimeout(this.getPriceTypes(source, true), 500);
          });
      }
      return this.priceTypes;
    },
    getCurrencies(source, forceGet) {
      if (source == 'created' && (forceGet || this.currencies == null || this.currencies.length == 0)) {
        Http()
          .get(ENDPOINTS.currencies, {
          })
          .then((response) => {
            if (response.status) {
              this.currencies = response.data;
              if (response.dialog != null && response.dialog.message != null) {
                this.$toast.success(response.dialog.message)
              }
            } else {
              if (response.dialog != null && response.dialog.message != null) {
                this.$toast.error(response.dialog.message)
              }
            }
          }).catch((e) => {
            setTimeout(this.getCurrencies(source, true), 500);
          });
      }

    },
    getSchemaPrices() {
      Http()
        .post(ENDPOINTS.schemaPrices, {
          denominationId: this.denomination.id
        })
        .then((response) => {
          if (response.status) {
            if (response.data.length > 0) {
              this.schemaPrices = response.data.sort((a, b) => a.amountFrom - b.amountFrom);
              this.schemaPriceList = []
              if (this.schemaPrices.length > 0){
                if(this.schemaPrices.at(0).amountFrom == null){
                  this.fixedSchemaPrice = true
                } else {
                  this.fixedSchemaPrice = false
                }
              }
              this.showExistingSchema = true
              this.showSetFileConfigurations = true
              this.showSetSchool = true
              this.showCreateApplication = true
              if (this.applicationType == "online") {
                this.showCreateMenuItem = true
              }
            } else {
              this.schemaPrices = []
              this.showExistingSchema = false
              // this.showCreateSchemaPrice = true
            }
            if (response.dialog != null && response.dialog.message != null) {
              this.$toast.success(response.dialog.message)
            }
          } else {
            if (response.dialog != null && response.dialog.message != null) {
              this.$toast.error(response.dialog.message)
            }
          }
        })
        .catch((error) => {
          this.$toast.error("Internal Server Error!")
        })
    },
    addSchemaPrice(price) {
      if (price == null) {
        if (this.checkIfEmpty(this.selectedService)) {
          this.$toast.error("Please Select A Service")
          return;
        }
        // if (!this.fixedSchemaPrice && (this.checkIfEmpty(this.schemaPrice.amountFrom) || this.checkIfEmpty(this.schemaPrice.amountTo))) {
        //   this.$toast.error("Amounts Are Invalid")
        //   return;
        // }
        if (this.checkIfEmpty(this.schemaPrice.priceType)) {
          this.$toast.error("Price Type Is Invalid")
          return;
        }
        if (this.checkIfEmpty(this.schemaPrice.currencyId)) {
          this.$toast.error("Currency Is Invalid")
          return;
        }
        if (this.checkIfEmpty(this.schemaPrice.discount)) {
          this.$toast.error("Discount Is Invalid")
          return;
        }
        if (this.checkIfEmpty(this.schemaPrice.salePrice)) {
          this.$toast.error("Sale Price Is Invalid")
          return;
        }

        var obj = {}
        obj["amountFrom"] = this.schemaPrice.amountFrom
        obj["amountTo"] = this.schemaPrice.amountTo
        obj["priceType"] = this.schemaPrice.priceType
        obj["currencyId"] = this.schemaPrice.currencyId
        obj["discount"] = this.schemaPrice.discount
        obj["salePrice"] = this.schemaPrice.salePrice
        this.schemaPriceList.push(obj)
        this.schemaPrice.amountFrom = null
        this.schemaPrice.amountTo = null
        this.schemaPrice.priceType = null
        this.schemaPrice.discount = null
        this.schemaPrice.salePrice = null
      } else {
        var old = this.schemaPriceList.filter(f => f == price)
        if (old[0].amountFrom != null && old[0].amountTo != null) {
          this.fixedSchemaPrice = false
        }
        this.schemaPrice.amountFrom = old[0].amountFrom
        this.schemaPrice.amountTo = old[0].amountTo
        this.schemaPrice.priceType = old[0].priceType
        this.schemaPrice.discount = old[0].discount
        this.schemaPrice.salePrice = old[0].salePrice
        const index = this.schemaPriceList.indexOf(old[0]);
        if (index > -1) {
          this.schemaPriceList.splice(index, 1);
        }
      }
    },
    createSchemaPrice() {
      if (this.fixedSchemaPrice) {
        this.schemaPriceList = []
        this.addSchemaPrice(null)
      }
      if (this.checkIfEmpty(this.schemaPriceList) || this.schemaPriceList.length == 0) {
        this.$toast.error("Please Add Schema Prices")
      } else {
        Http()
          .post(ENDPOINTS.createSchemaPrice, {
            fixedPrice: this.fixedSchemaPrice,
            denominationId: this.denomination.id,
            serviceId: this.selectedService != null ? this.selectedService : this.service.id,
            prices: this.schemaPriceList,
          })
          .then((response) => {
            if (response.status) {
              this.showCreateSchema = false
              this.schemaPriceList = []
              if (!this.fixedSchemaPrice){
                this.schemaPrices.push(...response.data)
              } else {
                this.schemaPrices = response.data
              }
              this.schemaPrices = this.schemaPrices.sort((a, b) => a.amountFrom - b.amountFrom);
              this.$refs.closeSchemaPriceModal.click()
              this.showSetFileConfigurations = true
              this.showSetSchool = true
              this.showCreateApplication = true
              if (this.applicationType == "online") {
                this.showCreateMenuItem = true
              }
              if (response.dialog != null && response.dialog.message != null) {
                this.$toast.success(response.dialog.message)
              }
            } else {
              if (response.dialog != null && response.dialog.message != null) {
                this.$toast.error(response.dialog.message)
              }
              if (response.code == "error.schema.price.exists") {
                this.showExistingSchema = true
                this.schemaPrices = response.data
                this.$refs.closeSchemaPriceModal.click()
                this.showSetFileConfigurations = true
                this.showSetSchool = true
                this.showCreateApplication = true
                if (this.applicationType == "online") {
                  this.showCreateMenuItem = true
                }
              }
            }
          })
          .catch((error) => {
            this.$toast.error("Internal Server Error!")
          })
      }
    },
    deleteSchemaPrice(price) {
      if (confirm("Are you sure you want to delete the selected schema price?")) {
        Http()
          .post(ENDPOINTS.deleteSchemaPrice, {
            schemaPriceId: price.id,
          })
          .then((response) => {
            if (response.status) {
              var toRemove = this.schemaPrices.indexOf(price);
              this.schemaPrices.splice(toRemove, 1);
              this.schemaPriceList = []
              if (response.dialog != null && response.dialog.message != null) {
                this.$toast.success(response.dialog.message)
              }
            } else {
              if (response.dialog != null && response.dialog.message != null) {
                this.$toast.error(response.dialog.message)
              }
            }
          })
      }
    },
  },
}
</script>

<style scoped>

.subTitle{
  font-size: 23px;
  font-weight: 500;
}

.btn-primary{
  background-color: #E40046!important;
}

input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button {  

   opacity: 1;

}
</style>
