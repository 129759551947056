<template>
  <div>
    <div class="row mt-3">
      <div class="col-12">
        <label class="subTitle mb-3"><i class="bi bi-dash me-2"></i>Provider</label>
      </div>
      <div class="col-6">
        <div class="mb-3 form-check">
          <input type="radio" name="setProviderMapping" class="form-check-input" id="whishMoneyProvider" :value="true"
            data-bs-toggle="modal" data-bs-target="#newProviderMappingModal" />
          <label class="form-check-label" for="whishMoneyProvider">Add Provider</label>
        </div>
      </div>
      <div class="col-6">
        <div class="mb-3 form-check">
          <input type="radio" name="setProviderMapping" class="form-check-input" id="newProviderMapping"
            v-model="newProviderMapping" :value="false" />
          <label class="form-check-label" for="newProviderMapping">No Provider</label>
        </div>
      </div>
    </div>
    <div class="modal fade" id="newProviderMappingModal" tabindex="-1" aria-labelledby="newProviderMappingModal"
      aria-hidden="true">
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-header">
            <label class="modal-title subTitle">New Provider Mapping</label>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <div class="container-fluid">
              <div class="row">
                <div class="col-4">
                  <div class="mb-3" v-if="!quicqpay">
                    <label class="form-label">Provider <span class="text-danger">*</span></label>
                    <input type="text" class="form-control" v-model="provider" :disabled="true" />
                  </div>
                  <div class="mb-3" v-else>
                    <label class="form-label">Web Server <span class="text-danger">*</span></label>
                    <div class=" d-inline-flex w-100">
                      <v-select v-if="webServers != null" :options="webServers" label="name" class="vselect w-100"
                        placeholder="Choose Web Server" v-model="denomination.webServerId"
                        :reduce="webServerId => webServerId.id">
                        <template slot="option" slot-scope="option">
                          <span class="pl-4">{{ option.name }}</span>
                        </template>
                      </v-select>
                      <button type="button" class="btn btn-secondary ms-1" @click="getWebServers('created', true)"><i
                          class="bi bi-arrow-clockwise"></i></button>
                    </div>
                  </div>
                </div>
                <div class="col-4">
                  <div class="mb-3">
                    <label class="form-label">{{ quicqpay ? 'Merchant Admin ID' : 'Whish Money Service ID'}} <span
                        class="text-danger">*</span></label>
                    <input type="number" class="form-control" v-model="denomination.externalId" />
                  </div>
                </div>
                <div class="col-4" v-if="applicationType == 'online' && !quicqpay">
                  <div class="mb-3">
                    <label class="form-label">Bill Type <span class="text-danger">*</span></label>
                    <input type="text" class="form-control" v-model="providerBillType" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" ref="closeMappingModal" data-bs-dismiss="modal"
              @click="newProviderMapping = true">Close</button>
            <button type="button" class="btn btn-primary" @click="createNewProviderMapping">Create Provider
              Mapping</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import Http, { ENDPOINTS } from "../../request";
import {commonFunctions} from "../../helpers/CommonFunctions"

export default {
  components:{
        vSelect,
    },
  props:{
      denomination: Object,
      applicationType: String,
      quicqpay: Boolean,
  },
  data(){
      return{
        checkIfEmpty: commonFunctions.checkIfEmpty,
        newProviderMapping: null,
        providerBillType: null,
        showCreateSchemaPrice: false,
        provider: null,
        webServers: []
      }
  },
  watch: {
    newProviderMapping() {
      if (!this.newProviderMapping) {
        this.showCreateSchemaPrice = true
      }
    },
    showCreateSchemaPrice() {
      this.$emit('changeShowCreateSchemaPrice', this.showCreateSchemaPrice)
    },
  },
  created(){
    this.provider = !this.quicqpay ?  "Whish Money" : null
    this.getWebServers("created", true);
  },
  methods: {
    getWebServers(source, forceGet) {
            if (source == 'created' && (forceGet || this.webServers == null || this.webServers.length == 0)) {
                Http()
                    .get(ENDPOINTS.webServers, {
                    })
                    .then((response) => {
                        if (response.status) {
                            this.webServers = response.data;
                            if (response.dialog != null && response.dialog.message != null) {
                                this.$toast.success(response.dialog.message)
                            }
                        } else {
                            if (response.dialog != null && response.dialog.message != null) {
                                this.$toast.error(response.dialog.message)
                            }
                        }
                    }).catch((e) => {
                        setTimeout(this.getWebServers(source, true), 500);
                    });
            }
            return this.webServers;
        },
    createNewProviderMapping() {
      if (this.checkIfEmpty(this.denomination.externalId) || (this.checkIfEmpty(this.providerBillType) && this.applicationType == 'online' && !this.quicqpay)) {
        this.$toast.error("Please Fill All Required Fields");
        return;
      }
      if (this.quicqpay && this.denomination.webServerId == null){
        this.$toast.error("Invalid Web Server");
        return;
      }
      Http()
        .post(ENDPOINTS.createProviderDenominaitonMapping, {
          denominationId: this.denomination.id,
          externalDenomination: this.denomination.externalId,
          billType: this.providerBillType,
          quicqpay: this.quicqpay,
          webServerId: this.denomination.webServerId
        })
        .then((response) => {
          if (response.status) {
            this.newProviderMapping = false
            this.showCreateSchemaPrice = true
            this.$refs.closeMappingModal.click()
            if (response.dialog != null && response.dialog.message != null) {
              this.$toast.success(response.dialog.message)
            }
          } else {
            if (response.dialog != null && response.dialog.message != null) {
              this.$toast.error(response.dialog.message)
            }
          }
        })
        .catch((error) => {
          this.$toast.error("Internal Server Error!")
        })
    },
  }
}
</script>


<style scoped>
.subTitle{
  font-size: 23px;
  font-weight: 500;
}

.btn-primary{
  background-color: #E40046!important;
}

input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button {  

   opacity: 1;

}
</style>