<template>
  <div class="mx-5 py-5" style="text-align: left !important">
    <div class="row">
      <div class="col-12 mt-2">
        <label class="title">Create Collection Application</label>
      </div>
      <div class="text-right">
        <button class="btn btn-secondary" type="button" @click="reloadPage">Reset</button>
      </div>
    </div>
      <div class="row">
        <div class="col-12">
          <label class="subTitle mb-3"><i class="bi bi-dash me-2"></i>Application Type</label>
        </div>
        <div class="col-6">
          <div class="mb-3 form-check">
            <input class="form-check-input" type="radio" name="applicationType" id="offlineCollection" v-model="applicationType" value="offline" :disabled="selectedService != null" />
            <label class="form-check-label" for="offlineCollection">Offline Application</label>
          </div>
        </div>
        <div class="col-6">
          <div class="mb-3 form-check">
            <input type="radio" name="applicationType" class="form-check-input" id="onlineollection" v-model="applicationType" value="online" :disabled="selectedService != null"/>
            <label class="form-check-label" for="onlineollection">Online Application</label>
          </div>
        </div>
      </div>
     <div v-if="applicationType != null" class="row">
      <div class="col-12">
        <label class="subTitle mb-3" style="font-size: 20px;">Settlement</label>
      </div>
      <div class="col-6">
        <div class="mb-3 form-check">
          <input class="form-check-input" type="radio" name="applicationSettlement" id="appSettlement" v-model="applicationSettlement" value="Whish App" />
          <label class="form-check-label" for="appSettlement">Whish App</label>
        </div>
      </div>
      <div class="col-6">
        <div class="mb-3 form-check">
          <input type="radio" name="applicationSettlement" class="form-check-input" id="moneySettlement" v-model="applicationSettlement" value="Whish Money" />
          <label class="form-check-label" for="moneySettlement">Whish Money</label>
        </div>
      </div>
     </div> 
     <div v-if="applicationType == 'online'" class="row">
      <div class="col-12">
        <label class="subTitle mb-3" style="font-size: 20px;">Type</label>
      </div>
      <div class="col-6">
        <div class="mb-3 form-check">
          <input class="form-check-input" type="checkbox" name="quicqpay" id="quicqpay" v-model="quicqpay"  />
          <label class="form-check-label" for="quicqpay">Quicqpay</label>
        </div>
      </div>
     </div> 
      <set-service 
      :applicationType="applicationType" :applicationSettlement="applicationSettlement" 
      :service="service" @changeSelectedService="changeSelectedService" />

      <set-denomination v-if="selectedService" :quicqpay="quicqpay"
      :applicationType="applicationType" :applicationSettlement="applicationSettlement" :denomination="denomination" :selectedService="selectedService"
      @changeShowCreateSchemaPrice="changeShowCreateSchemaPrice" />

      <set-schema-price 
      :selectedService="selectedService" :applicationType="applicationType" :service="service" :showCreateSchemaPrice="showCreateSchemaPrice" :denomination="denomination"
      @changeShowCreateApplication="changeShowCreateApplication" @changeShowCreateMenuItem="changeShowCreateMenuItem"  />

      <set-application-and-fields v-if="showCreateApplication" :quicqpay="quicqpay"
      :applicationType="applicationType" :applicationSettlement="applicationSettlement" :denomination="denomination" :application="application"
      @changeShowCreateMenuItem="changeShowCreateMenuItem"  />

      <set-menu-item v-if="showCreateMenuItem"
      :applicationType="applicationType" :application="application" :denomination="denomination" :selectedService="selectedService"
      @changeShowCreateAdminUser="changeShowCreateAdminUser" />
      
      <create-admin-user v-if="showCreateAdminUser" :applicationId="application.id" 
      :applicationSettlement="applicationSettlement" :applicationType="applicationType" />
      <refresh-buttons v-if="showCreateAdminUser" />
  </div>
</template>

<script>
import Http, { ENDPOINTS } from "../request";
import SetService from '../components/CreateCollectionApplication/SetService.vue';
import SetDenomination from '../components/CreateCollectionApplication/SetDenomination.vue';
import SetSchemaPrice from '../components/CreateCollectionApplication/SetSchemaPrice.vue';
import SetApplicationAndFields from '../components/CreateCollectionApplication/SetApplicationAndFields.vue';
import SetMenuItem from '../components/CreateCollectionApplication/SetMenuItem.vue';
import CreateAdminUser from '../components/CreateCollectionApplication/CreateAdminUser.vue';
import RefreshButtons from '../components/CreateCollectionApplication/RefreshButtons.vue';
export default {
  components: {
    SetService,
    SetDenomination,
    SetSchemaPrice,
    SetApplicationAndFields,
    SetMenuItem,
    CreateAdminUser,
    RefreshButtons
  },
  data() {
    return {
      applicationType: null,
      applicationSettlement: null,
      selectedService: null,
      service:{
        id: null,
        name: null,
      },
      denomination: {
        id: null, 
        name: null,
        newImage: null,
        imageUrl: null,
        imageData: null,
        imageName: null,
        webServerId: null,
        settlement: null,
        autoAcceptTopup: true,
        bulkMultiBillPayment: false,
        commentId: null,
        accountingId: null,
        externalId:null,
        flag: 1
      },  
      application: {
        id: null,
        title: null,
        tags: [],
        quicqpay: false,
      },
      showCreateSchemaPrice: false,
      showCreateApplication: false,
      showCreateMenuItem: false,
      showCreateAdminUser: false,

      globalOperators: null,
      providers: null,
      quicqpay: false,
    };
  },
  created(){
    // this.getGlobalOperatorsBeans("created");
    // this.getProviders();
  },
  watch: {
  },
  methods:{
    getProviders(source, forceGet) {
      if (source == 'created' && (forceGet || this.providers == null || this.providers.length == 0)) {
        Http()
          .get(ENDPOINTS.externalProvidersBeans, {
          })
          .then((response) => {
            if (response.status) {
              this.providers = response.data;
              if (response.dialog != null && response.dialog.message != null) {
                this.$toast.success(response.dialog.message)
              }
            } else {
              if (response.dialog != null && response.dialog.message != null) {
                this.$toast.error(response.dialog.message)
              }
            }
          }).catch((e) => {
            setTimeout(this.getProviders(source, true), 500);
          });
      }
      return this.providers;
    },
    getGlobalOperatorsBeans(source,forceGet){
      if(source=='created' && (forceGet || this.globalOperators==null || this.globalOperators.length==0)){
      Http()
        .get(ENDPOINTS.globalOperatorsBeans, {
        })
        .then((response) => {
          if (response.status) {
            this.globalOperators = response.data;
            if (response.dialog != null && response.dialog.message != null) {
              this.$toast.success(response.dialog.message)
            }
          } else {
            if (response.dialog != null && response.dialog.message != null) {
              this.$toast.error(response.dialog.message)
            }
          }
        }).catch((e)=>{
          setTimeout(this.getGlobalOperatorsBeans(source,true),500);
        });
      }
      return this.globalOperators;
    },
    changeSelectedService(selected){
      this.selectedService = selected
    },
    changeShowCreateSchemaPrice(selected){
      this.showCreateSchemaPrice = selected
    },
    changeShowCreateApplication(selected){
      this.showCreateApplication = selected
    },
    changeShowCreateMenuItem(selected){
      this.showCreateMenuItem = selected
    },
    changeShowCreateAdminUser(selected){
      this.showCreateAdminUser = selected
    },
    reloadPage() {
      window.location.reload();
    },
  }
};
</script>

<style scoped>
.title {
  font-size: 30px;
  font-weight: bold;
}

.subTitle{
  font-size: 23px;
  font-weight: 500;
}

.btn-primary{
  background-color: #E40046!important;
}

input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button {  

   opacity: 1;

}
</style>
