function checkIfEmpty(value) {
    return value === null || value === ''
}

function getPrivilges(privileges) {
    return {
        ActiveAccounts: privileges.includes("global_admin") || privileges.includes("thirdparty_active_accounts.show"),
        deleteAccounts: privileges.includes("global_admin") || privileges.includes("thirdparty_active_accounts.button.delete"),
        Applications: privileges.includes("global_admin") || privileges.includes("thirdparty_applications.show"),
        patchApplications: privileges.includes("global_admin") || privileges.includes("thirdparty_applications.action.application.patch"),
        BalanceStatement: privileges.includes("global_admin") || privileges.includes("thirdparty_balance_statement.show"),
        Collect: privileges.includes("global_admin") || privileges.includes("thirdparty_collect.show"),
        collectAction: privileges.includes("global_admin") || privileges.includes("thirdparty_collect.action.collect"),
        Collections: privileges.includes("global_admin") || privileges.includes("thirdparty_collection.show"),
        CollectionApi: privileges.includes("global_admin") || privileges.includes("thirdparty_collection_api_report.show"),
        OnlinePayments: privileges.includes("global_admin") || privileges.includes("thirdparty_online_payments.show"),
        OnlinePaymentsSearchByAccountId: privileges.includes("global_admin") || privileges.includes("thirdparty_online_payments.search.by.accountId"),
        Payments: privileges.includes("global_admin") || privileges.includes("thirdparty_payments.show"),
        Sales: privileges.includes("global_admin") || privileges.includes("thirdparty_sales.show"),
        Statement: privileges.includes("global_admin") || privileges.includes("thirdparty_statement.show"),
        CreateCollectionApplication: privileges.includes("global_admin") || privileges.includes("thirdparty_create_collection_application.show"),
        UploadPaymentsConfigurations: privileges.includes("global_admin") || privileges.includes("thirdparty_upload_payments_configurations.show"),
        UploadPayments: privileges.includes("global_admin") || privileges.includes("thirdparty_upload_payments.show"),
        SwitchServer: privileges.includes("global_admin") || privileges.includes("switch_server"),
        SchoolConfiguration: privileges.includes("global_admin") || privileges.includes("thirdparty_school_configuration.show"),
        MontyPay: privileges.includes("global_admin") || privileges.includes("monty_pay_report.show"),
    }
}

function formatNumber(value) {
    if (value != null) {
        return value.toLocaleString("en-US");
    }
}

export const commonFunctions = { checkIfEmpty, getPrivilges, formatNumber };