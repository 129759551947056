<template>
  <div>
    <div class="row mt-3">
        <div class="col-12">
          <label class="subTitle mb-3"><i class="bi bi-dash me-2"></i>Admin User</label>
        </div>
        <div class="row">
          <div class="col-6">
          <div class="mb-3 form-check">
            <input type="radio" name="setAdminUser" class="form-check-input" id="adminUser" v-model="newAdminUser" :value="false" data-bs-toggle="modal"  />
            <label class="form-check-label" for="adminUser">Choose Existing Admin User</label>
          </div>
        </div>
          <div class="col-6">
            <div class="mb-3 form-check">
            <input type="radio" name="setAdminUser" class="form-check-input" id="newAdminUser" v-model="newAdminUser" :value="true" data-bs-toggle="modal" data-bs-target="#newAdminUserModal" />
            <label class="form-check-label" for="newAdminUser">Create New Admin User</label>
          </div>
        </div>
        </div>
        <div v-if="newAdminUser != null && !newAdminUser">
        <div class="row">
            <div class="col-4 d-inline-flex">
            <v-select v-if="adminUsers != null" :options="adminUsers" label="name" class="vselect w-100" placeholder="Choose Admin User" v-model="adminUser.id" :reduce="id => id.id" >
                <template slot="option" slot-scope="option" >
                    <span class="pl-4">{{ option.userName + " - " + option.name }}</span>
                </template>
            </v-select>
            <button type="button" class="btn btn-secondary ms-1" @click="getUsers('created', true)"><i class="bi bi-arrow-clockwise"></i></button>
            </div>
            <div class="col-4">
            <button type="button" class="btn btn-primary" @click="checkUser" :disabled="adminUser.id == null">Confirm</button>
            </div>
        </div>
        </div>
    </div>
    <div class="modal fade"  id="newAdminUserModal" tabindex="-1" aria-labelledby="newAdminUserModal" aria-hidden="true">
        <div class="modal-dialog modal-lg">
          <div class="modal-content">
            <div class="modal-header">
              <label class="modal-title subTitle">Create Admin User</label>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
              <div class="container-fluid">
                <div class="row">
                  <div class="col-6">
                    <div class="mb-3">
                      <label class="form-label">Name <span class="text-danger">*</span></label>
                      <input type="text" class="form-control" v-model="adminUser.name"/>
                    </div>
                  </div>
                  <div class="col-6">
                    <div class="mb-3">
                      <label class="form-label">Username <span class="text-danger">*</span></label>
                      <input type="text" class="form-control" v-model="adminUser.userName"/>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-secondary" ref="closeAdminUserModal" data-bs-dismiss="modal">Close</button>
              <button type="button" class="btn btn-primary" @click="createAdminUser">Create Admin User</button>
            </div>
          </div>
        </div>
      </div>
      <div v-if="createdAdminUser != null" class="row" style="margin-top: 20px;">
        <div class="col-12">
          <table class="table table-bordered">
            <thead>
              <tr>
                <th>ID</th>
                <th>Name</th>
                <th>Username</th>
                <th>Password</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{{createdAdminUser.id}}</td>
                <td>{{createdAdminUser.name}}</td>
                <td>{{createdAdminUser.userName}}</td>
                <td>{{createdAdminUser.password}}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
            <div class="mb-3">
              <label class="subTitle">Create Admin API Session</label>
            </div>
        </div>
        <div class="col-4">
          <div class="mb-3">
              <label class="form-label">Linked Account Id <small class="text-danger">{{linkedAccount.name}}</small> <span class="text-danger">*</span></label>
              <input  @blur="accountSearch" type="text" class="form-control" v-model="linkedAccount.id"/>
            </div>
        </div>
        <div class="col-4 pt-3" style="margin: auto 0">
          <button type="button" class="btn btn-primary" @click="createAdminApiSession" :disabled="!accountChecked">Create Session</button>
        </div>
      </div>
  </div>
</template>

<script>
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import Http, { ENDPOINTS } from "../../request";
import {commonFunctions} from "../../helpers/CommonFunctions"
export default {
    components:{
        vSelect,
  },
  data() {
    return {
      newAdminUser: false,
      adminUser: {
        id: null,
        name: null,
        userName: null,
        password: null,
      },
      createdAdminUser: {
        id: null,
        name: null,
        userName: null,
        password: null,
      },
      adminUsers: null,
      checkIfEmpty: commonFunctions.checkIfEmpty,
      linkedAccount: {
        id: null,
        name: null,
      },
      accountChecked: false
    }
  },
  props: {
    applicationId: Number,
    applicationSettlement: String,
    applicationType: String,
  },
  created() {
    this.getUsers("created", true);
  },
  methods: {
    getUsers(source, forceGet) {
      if ((forceGet || this.adminUsers == null || this.adminUsers.length == 0)) {
        Http()
          .get(ENDPOINTS.adminUserList, {
          })
          .then((response) => {

            if (response.status) {
              this.adminUsers = response.data;
              if (response.dialog != null && response.dialog.message != null) {
                this.$toast.success(response.dialog.message)
              }
            } else {
              if (response.dialog != null && response.dialog.message != null) {
                this.$toast.error(response.dialog.message)
              }
            }
          }).catch((e) => {
            setTimeout(this.getUsers(source, true), 500);
          });
      }
      return this.adminUsers;
    },
    checkUser() {
      Http()
        .post(ENDPOINTS.createAdminUser, {
          applicationId: this.applicationId,
          applicationType: this.applicationType,
          settlement: this.applicationSettlement,
          userId: this.adminUser.id
        })
        .then((response) => {
          if (response.status) {
            this.createdAdminUser = response.data
            this.adminUser.password = null;
            if (response.dialog != null && response.dialog.message != null) {
              this.$toast.success(response.dialog.message)
            }
          } else {
            if (response.dialog != null && response.dialog.message != null) {
              this.$toast.error(response.dialog.message)
            }
          }
        })
        .catch((error) => {
          this.$toast.error("Internal Server Error!")
        })
    },
    createAdminUser() {
      if (this.checkIfEmpty(this.adminUser.name)) {
        this.$toast.error("Name Has Invalid Value");
        return;
      }
      if (this.checkIfEmpty(this.adminUser.userName)) {
        this.$toast.error("UserName Has Invalid Value");
        return;
      }
      this.adminUser.userName = this.adminUser.userName.toLowerCase()
      this.adminUser.name = this.adminUser.name.toUpperCase()
      Http()
        .post(ENDPOINTS.createAdminUser, {
          applicationId: this.applicationId,
          settlement: this.applicationSettlement,
          applicationType: this.applicationType,
          ...this.adminUser
        })
        .then((response) => {
          if (response.status) {
            this.createdAdminUser = response.data
            this.$refs.closeAdminUserModal.click()
            if (response.dialog != null && response.dialog.message != null) {
              this.$toast.success(response.dialog.message)
            }
          } else {
            if (response.dialog != null && response.dialog.message != null) {
              this.$toast.error(response.dialog.message)
            }
          }
        })
        .catch((error) => {
          this.$toast.error("Internal Server Error!")
        })
    },
    createAdminApiSession() {
      var userId = this.newAdminUser ? this.createdAdminUser.id : this.adminUser.id
      if (userId == null) {
        this.$toast.error("Invalid User");
        return;
      }
      if (this.checkIfEmpty(this.linkedAccount.id)) {
        this.$toast.error("Linked Account ID Has Invalid Value");
        return;
      }
      Http()
        .post(ENDPOINTS.createAdminApiSession, {
          userId: userId,
          linkedAccountId: this.linkedAccount.id
        })
        .then((response) => {
          if (response.status) {
            if (response.dialog != null && response.dialog.message != null) {
              this.$toast.success(response.dialog.message)
            }
          } else {
            if (response.dialog != null && response.dialog.message != null) {
              this.$toast.error(response.dialog.message)
            }
          }
        })
        .catch((error) => {
          this.$toast.error("Internal Server Error!")
        })
    },
    accountSearch() {
      Http()
        .post(ENDPOINTS.getAccount, {
          accountId: this.linkedAccount.id
        })
        .then((response) => {
          if (response.status) {
            this.linkedAccount.name = response.data.name
            this.accountChecked = true
            if (response.dialog != null && response.dialog.message != null) {
              this.$toast.success(response.dialog.message)
            }
          } else {
            if (response.dialog != null && response.dialog.message != null) {
              this.$toast.error(response.dialog.message)
            }
          }
        })
        .catch((error) => {
          this.$toast.error("Internal Server Error!")
        })
    },
  }

}
</script>
<style scoped>

.subTitle{
  font-size: 23px;
  font-weight: 500;
}

.btn-primary{
  background-color: #E40046!important;
}

input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button {  

   opacity: 1;

}
</style>