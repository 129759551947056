<template>
    <div>
        <div class="row mt-3">
            <div class="col-12">
                <label class="subTitle mb-3"><i class="bi bi-dash me-2"></i>Set Denomination</label>
            </div>
            <div class="col-6">
                <div class="mb-3 form-check">
                    <input class="form-check-input" type="radio" name="setDenomination" id="existingDenomination" v-model="newDenomination" :value="false" />
                    <label class="form-check-label" for="existingDenomination">Existing Denomination</label>
                </div>
            </div>
            <div class="col-6">
                <div class="mb-3 form-check">
                    <input type="radio" name="setDenomination" class="form-check-input" id="newDenomination" v-model="newDenomination" :value="true" data-bs-toggle="modal" data-bs-target="#newDenominationeModal" />
                    <label class="form-check-label" for="newDenomination">New Denomination</label>
                </div>
            </div>
        </div>
        <div v-if="newDenomination != null && !newDenomination">
            <div class="row">
                <div class="col-4 d-inline-flex">
                    <v-select :options="denominations" label="name" class="vselect w-100" placeholder="Choose Denomination" v-model="denomination.id" :reduce="id => id.id">
                        <template slot="option" slot-scope="option">
                            <span class="pl-4">{{ option.id + " - " + option.name }}</span>
                        </template>
                    </v-select>
                    <button type="button" class="btn btn-secondary ms-1" @click="getDenominationBeans('selectedService', true)"><i class="bi bi-arrow-clockwise"></i></button>
                </div>
                <div class="col-4">
                    <button type="button" class="btn btn-primary" @click="checkDenomination" :disabled="denomination.id == null">Confirm</button>
                </div>
            </div>
        </div>
        <div class="modal fade" id="newDenominationeModal" tabindex="-1" aria-labelledby="newDenominationeModal" aria-hidden="true">
            <div class="modal-dialog modal-xl">
                <div class="modal-content">
                    <div class="modal-header">
                        <label class="modal-title subTitle">Create Denomination</label>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                        <div class="container-fluid">
                            <div class="row">
                                <div class="col-4">
                                    <div class="mb-3">
                                        <label class="form-label">Name <span class="text-danger">*</span></label>
                                        <input type="text" class="form-control" v-model="denomination.name" />
                                    </div>
                                </div>
                                <div class="col-4" v-if="applicationType == 'online' || (applicationType == 'offline' && applicationSettlement == 'Whish Money')">
                                    <div class="mb-3" v-if="applicationType == 'online'">
                                        <label class="form-label">Web Server <span class="text-danger">*</span></label>
                                        <div class=" d-inline-flex w-100">
                                            <v-select v-if="webServers != null" :options="webServers" label="name" class="vselect w-100" placeholder="Choose Web Server" v-model="denomination.webServerId" :reduce="webServerId => webServerId.id">
                                                <template slot="option" slot-scope="option">
                                                    <span class="pl-4">{{ option.name }}</span>
                                                </template>
                                            </v-select>
                                            <button type="button" class="btn btn-secondary ms-1" @click="getWebServers('created', true)"><i class="bi bi-arrow-clockwise"></i></button>
                                        </div>
                                    </div>
                                    <div class="mb-3" v-else>
                                        <label class="form-label">Web Server <span class="text-danger">*</span></label>
                                        <div class=" d-inline-flex w-100">
                                            <v-select :options="filterOfflineWebServers(webServers)" label="name" class="vselect w-100" placeholder="Choose Web Server" v-model="denomination.webServerId" :reduce="webServerId => webServerId.id">
                                                <template slot="option" slot-scope="option">
                                                    <span class="pl-4">{{ option.name }}</span>
                                                </template>
                                            </v-select>
                                            <button type="button" class="btn btn-secondary ms-1"
                                                @click="getWebServers('created', true)"><i class="bi bi-arrow-clockwise"></i></button>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-4"
                                    v-if="(applicationType == 'offline' && applicationSettlement == 'Whish Money') || quicqpay">
                                    <div class="mb-3">
                                        <label class="form-label">{{quicqpay ? 'Merchant Admin ID' : 'Whish Money Service ID'}} <span class="text-danger">*</span></label>
                                        <input type="number" class="form-control" v-model="denomination.externalId" />
                                    </div>
                                </div>
                                <div class="col-4" style="margin: auto 0;">
                                    <div class="mb-3 form-check">
                                        <input type="checkbox" class="form-check-input" id="autoAcceptTopup" v-model="denomination.autoAcceptTopup">
                                        <label class="form-check-label" for="autoAcceptTopup"> Auto Accept Topup<span class="text-danger"> *</span></label>
                                    </div>
                                </div>
                                <div class="col-4" style="margin: auto 0;">
                                    <div class="mb-3 form-check">
                                        <input type="checkbox" class="form-check-input" id="bulkMultiBillPayment" v-model="denomination.bulkMultiBillPayment">
                                        <label class="form-check-label" for="bulkMultiBillPayment"> Bulk Multi Bill Payment<span class="text-danger"> *</span></label>
                                    </div>
                                </div>
                                <!-- <div class="col-4" style="margin: auto 0;">
                                    <div class="mb-3 form-check">
                                    <input type="checkbox" class="form-check-input" id="topupWhishAccount" v-model="denomination.topupWhishAccount">
                                    <label class="form-check-label" for="topupWhishAccount"> Topup Whish Account<span class="text-danger"> *</span></label>
                                    </div>
                                </div> -->
                            </div>
                            <div class="row" v-if="(((applicationType == 'offline' && applicationSettlement == 'Whish App') || applicationType == 'school' || quicqpay) && selectedService != null)">
                                <div class="col-4">
                                    <div class="mb-3">
                                        <label class="form-label">Accountings <span class="text-danger">*</span></label>
                                        <div class=" d-inline-flex w-100">
                                            <v-select v-if="accountings != null" :options="accountings" label="name" class="vselect w-100" placeholder="Choose Accounting" v-model="denomination.accountingId" :reduce="accountingId => accountingId.id">
                                                <template slot="option" slot-scope="option">
                                                    <span class="pl-4">{{ option.name }}</span>
                                                </template>
                                            </v-select>
                                            <button type="button" class="btn btn-secondary ms-1"
                                                @click="getAccountings('created', true)"><i class="bi bi-arrow-clockwise"></i></button>
                                            <button type="button" class="btn btn-secondary ms-1" @click="accounting.add = true"><i class="bi bi-plus-circle"></i></button>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-4" v-if="accounting.add">
                                    <div class="mb-3">
                                        <label class="form-label">Accounting <span class="text-danger">*</span></label>
                                        <div class=" d-inline-flex w-100">
                                            <input type="text" class="form-control" v-model="accounting.name" />
                                            <button type="button" class="btn btn-secondary ms-1" @click="createAccounting"><i class="bi bi-plus-circle"></i></button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row" v-if="(((applicationType == 'offline' && applicationSettlement == 'Whish App') || applicationType == 'school'|| quicqpay ) && selectedService != null)">
                                <div class="col-4">
                                    <div class="mb-3">
                                        <label class="form-label">Comments <span class="text-danger">*</span></label>
                                        <div class=" d-inline-flex w-100">
                                            <v-select v-if="comments != null" :options="comments" label="name" class="vselect w-100" placeholder="Choose Comment" v-model="denomination.commentId" :reduce="commentId => commentId.id">
                                                <template slot="option" slot-scope="option">
                                                    <span class="pl-4">{{ option.name }}</span>
                                                </template>
                                            </v-select>
                                            <button type="button" class="btn btn-secondary ms-1"
                                                @click="getComments('created', true)"><i class="bi bi-arrow-clockwise"></i></button>
                                            <button type="button" class="btn btn-secondary ms-1" @click="comment.add = true"><i class="bi bi-plus-circle"></i></button>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-4" v-if="comment.add">
                                    <div class="mb-3">
                                        <label class="form-label">comment <span class="text-danger">*</span></label>
                                        <div class="d-inline-flex w-100">
                                            <input type="text" class="form-control" v-model="comment.name" />
                                            <button type="button" class="btn btn-secondary ms-1" @click="createComment"><i class="bi bi-plus-circle"></i></button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row" v-if="applicationType == 'school'">
                                <div class="col-4">
                                    <div class="mb-3">
                                        <label class="form-label">Receiver Account ID <span class="text-danger">*</span></label>
                                        <input type="text" class="form-control" v-model="denomination.receiverAccountId" />
                                    </div>
                                </div>
                                <div class="col-4" style="margin: auto 0px">
                                    <button type="button" class="btn btn-primary" @click="checkReceiverAccountId" :disabled="denomination.receiverAccountId == null">Confirm</button>
                                </div>
                            </div>
                            <ImageChooser :documents="denominationDocuments" :imageInformation="denomination" :refreshDocuments="getDenominationDocumnets" :isMenu="false" />
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" ref="closeDenominationModal" data-bs-dismiss="modal" @click="newDenomination = false">Close</button>
                        <button type="button" class="btn btn-primary" @click="createDenomination" :disabled="applicationType == 'school' && !receiverAccountChecked">Create Denomination</button>
                </div>
            </div>
        </div>
        </div>
        <div v-if="showChangeCommentAndAccounting && (applicationSettlement == 'Whish App' || applicationType == 'school'|| quicqpay)">
            <div class="row mt-3">
              <div class="col-6">
                <label class="subTitle mb-3"><i class="bi bi-dash me-2"></i>Change Comment And Accounting</label>
              </div>
              <div class="col-6 text-right">
                <button type="button" class="btn btn-primary" @click="checkAccountingAndComment" :disabled="denomination.accountingId == null || denomination.commentId == null || (applicationType == 'school' && !receiverAccountChecked)">Confirm</button>
              </div>
            </div>
            <div class="row">
              <div class="col-4">
                <div class="mb-3">
                  <label class="form-label">Accountings <span class="text-danger">*</span></label>
                  <div class=" d-inline-flex w-100">
                    <v-select :options="accountings" label="name" class="vselect w-100" placeholder="Choose Accounting" v-model="denomination.accountingId" :reduce="accountingId => accountingId.id" >
                      <template slot="option" slot-scope="option" >
                          <span class="pl-4">{{ option.name }}</span>
                      </template>
                    </v-select>
                    <button type="button" class="btn btn-secondary ms-1" @click="getAccountings('created', true)"><i class="bi bi-arrow-clockwise"></i></button>
                    <button type="button" class="btn btn-secondary ms-1"  @click="accounting.add = true"><i class="bi bi-plus-circle"></i></button>
                  </div>
                </div>
              </div>
              <div class="col-4" v-if="accounting.add">
                <div class="mb-3">
                  <label class="form-label">Accounting <span class="text-danger">*</span></label>
                  <div class=" d-inline-flex w-100">
                    <input type="text" class="form-control" v-model="accounting.name" />
                    <button type="button" class="btn btn-secondary ms-1"  @click="createAccounting"><i class="bi bi-plus-circle"></i></button>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-4">
                <div class="mb-3">
                  <label class="form-label">Comments <span class="text-danger">*</span></label>
                  <div class=" d-inline-flex w-100">
                    <v-select :options="comments" label="name" class="vselect w-100" placeholder="Choose Comment" v-model="denomination.commentId" :reduce="commentId => commentId.id" >
                      <template slot="option" slot-scope="option" >
                          <span class="pl-4">{{ option.name }}</span>
                      </template>
                    </v-select>
                    <button type="button" class="btn btn-secondary ms-1" @click="getComments('created', true)"><i class="bi bi-arrow-clockwise"></i></button>
                    <button type="button" class="btn btn-secondary ms-1"  @click="comment.add = true"><i class="bi bi-plus-circle"></i></button>
                  </div>
                </div>
              </div>
              <div class="col-4" v-if="comment.add">
                <div class="mb-3">
                  <label class="form-label">comment <span class="text-danger">*</span></label>
                  <div class="d-inline-flex w-100">
                    <input type="text" class="form-control" v-model="comment.name" />
                    <button type="button" class="btn btn-secondary ms-1"  @click="createComment"><i class="bi bi-plus-circle"></i></button>
                  </div>
                </div>
              </div>
            </div>
            <div class="row" v-if="applicationType == 'school'">
                <div class="col-4">
                    <div class="mb-3">
                        <label class="form-label">Receiver Account ID <span class="text-danger">*</span></label>
                        <input type="text" class="form-control" v-model="denomination.receiverAccountId" />
                    </div>
                </div>
                <div class="col-4" style="margin: auto 0px">
                    <button type="button" class="btn btn-primary" @click="checkReceiverAccountId" :disabled="denomination.receiverAccountId == null">Confirm</button>
                </div>
            </div>
            <button type="button" class="d-none" data-bs-toggle="modal" ref="checkAccountingAndCommentBtn" data-bs-target="#checkAccountingAndCommentModal">test</button>
            <div  class="modal fade" id="checkAccountingAndCommentModal" tabindex="-1" aria-labelledby="checkAccountingAndCommentModal" aria-hidden="true">
                <div class="modal-dialog modal-lg">
                  <div class="modal-content">
                    <div class="modal-header">
                      <label class="modal-title subTitle">Confirm Changing Accounting And Comment</label>
                      <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                      <div class="container-fluid">
                        <div class="row">
                            <div class="col-6">
                              <div class="mb-3">
                                <label class="form-label">Old Accounting:</label>
                                <input type="text" class="form-control" v-model="oldAccounting" disabled/>
                              </div>
                            </div>
                            <div class="col-6">
                              <div class="mb-3">
                                <label class="form-label">New Accounting: <span class="text-danger">*</span></label>
                                <v-select :options="accountings" label="name" class="vselect w-100" placeholder="Choose Accounting" v-model="denomination.accountingId" :reduce="accountingId => accountingId.id" >
                                    <template slot="option" slot-scope="option" >
                                        <span class="pl-4">{{ option.name }}</span>
                                    </template>
                                  </v-select>
                              </div>
                            </div>
                          <div class="col-6">
                            <div class="mb-3">
                              <label class="form-label">Old Comment:</label>
                              <input type="text" class="form-control" v-model="oldComment" disabled />
                            </div>
                          </div>
                          <div class="col-6">
                            <div class="mb-3">
                              <label class="form-label">New Comment: <span class="text-danger">*</span></label>
                              <v-select :options="comments" label="name" class="vselect w-100" placeholder="Choose Comment" v-model="denomination.commentId" :reduce="commentId => commentId.id" >
                                <template slot="option" slot-scope="option" >
                                    <span class="pl-4">{{ option.name }}</span>
                                </template>
                              </v-select>
                            </div>
                          </div>
                          <div class="col-6" v-if="applicationType == 'school'">
                            <div class="mb-3">
                              <label class="form-label">Old Receiver Account ID:</label>
                              <input type="text" class="form-control" v-model="oldReceiverAccountId" disabled />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="modal-footer">
                      <button type="button" class="btn btn-secondary" ref="closeCheckAccountingAndCommentModal" data-bs-dismiss="modal" >Close</button>
                      <button type="button" class="btn btn-primary"  @click="changeCommentAndAccounting">Confirm</button>
                    </div>
                  </div>
                </div>
              </div>
        </div>
        <div v-if="fakeItemFailed">
            <div class="row mt-3">
              <div class="col-12">
                <label class="subTitle mb-3">Create Fake Item</label>
              </div>
              <div class="col-4">
                <div class="mb-3">
                  <label >Denomination ID <span class="text-danger">*</span></label>
                  <input type="number" class="form-control" v-model="denomination.id" disabled />
                </div>
              </div>
              <div class="col-8" style="margin: auto 0">
                  <button type="button" class="btn btn-primary" @click="createFakeItem">Create</button>
              </div>
            </div>
        </div>
        <div v-if="(applicationType == 'online' && showProviderMapping) || (applicationType == 'offline' && applicationSettlement == 'Whish Money' && showProviderMapping)">
            <set-provider-denomination-mapping ref="setProviderDenominationMapping" 
            :applicationType="applicationType" :denomination="denomination" :quicqpay="quicqpay"
            @changeShowCreateSchemaPrice="changeShowCreateSchemaPrice"/>
          </div>
    </div>
</template>

<script>
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import ImageChooser from "../ImageChooser.vue";
import Http, { ENDPOINTS } from "../../request";
import SetProviderDenominationMapping from './SetProviderDenominationMapping.vue';
import {commonFunctions} from "../../helpers/CommonFunctions"
export default {
    components:{
        vSelect,
        ImageChooser,
        SetProviderDenominationMapping
    },
    props: {
        selectedService: Number,
        denomination: Object,
        applicationType: String,
        applicationSettlement: String,
        quicqpay: Boolean,
    },
    data() {
        return {
            checkIfEmpty: commonFunctions.checkIfEmpty,
            DOCUMENT_TYPE_DENOMINATION: 3,
            newDenomination: null,
            denominations: null,
            webServers: null,
            showChangeCommentAndAccounting: false,
            denominationDocuments: null,
            comment: {
                add: false,
                id: null,
                name: null,
            },
            accounting: {
                add: false,
                id: null,
                name: null,
            },
            comments: null,
            accountings: null,
            fakeItemFailed: false,
            showProviderMapping: false,
            showCreateSchemaPrice: false,
            oldAccounting: null,
            oldComment: null,
            oldReceiverAccountId: null,
            receiverAccountChecked: false,
        }
    },
    created(){
        this.getDenominationBeans("selectedService", true);
        this.getDenominationDocumnets("created", true);
        this.getWebServers("created", true);
        this.getComments("created", true);
        this.getAccountings("created", true);
    },
    watch: {
        showCreateSchemaPrice(){
            this.$emit('changeShowCreateSchemaPrice', this.showCreateSchemaPrice)
        },
        selectedService(){
            this.getDenominationBeans('selectedService', true)
        },
    },
    methods: {
        filterOfflineWebServers(webServers) {
            if(webServers != null){
                return webServers.filter(f => f.name.toLowerCase().includes("woo", "whish money"))
            }
        },
        getDenominationBeans(source, forceGet) {
            if (source == 'selectedService' && (forceGet || this.denominations == null || this.denominations.length == 0)) {
                Http()
                    .post(ENDPOINTS.denominationBeans, {
                        serviceId: this.selectedService,
                        includeInactive: true,
                    })
                    .then((response) => {
                        if (response.status) {
                            this.denominations = response.data;
                            if (response.dialog != null && response.dialog.message != null) {
                                this.$toast.success(response.dialog.message)
                            }
                        } else {
                            if (response.dialog != null && response.dialog.message != null) {
                                this.$toast.error(response.dialog.message)
                            }
                        }
                    })
                    .catch((e) => {
                        setTimeout(this.getDenominationBeans(source, true), 500);
                    });
            }
            return this.denominations;
        },
        getDenominationDocumnets(source, forceGet) {
            if (source == 'created' && (forceGet || this.denominationDocuments == null || this.denominationDocuments.length == 0)) {
                Http()
                    .post(ENDPOINTS.documents, {
                        type: this.DOCUMENT_TYPE_DENOMINATION
                    })
                    .then((response) => {
                        if (response.status) {
                            this.denominationDocuments = response.data;
                            if (response.dialog != null && response.dialog.message != null) {
                                this.$toast.success(response.dialog.message)
                            }
                        } else {
                            if (response.dialog != null && response.dialog.message != null) {
                                this.$toast.error(response.dialog.message)
                            }
                        }
                    }).catch((e) => {
                        setTimeout(this.getDenominationDocumnets(source, true), 500);
                    });
            }
            return this.denominationDocuments;
        },
        getWebServers(source, forceGet) {
            if (source == 'created' && (forceGet || this.webServers == null || this.webServers.length == 0)) {
                Http()
                    .get(ENDPOINTS.webServers, {
                    })
                    .then((response) => {
                        if (response.status) {
                            this.webServers = response.data;
                            if (response.dialog != null && response.dialog.message != null) {
                                this.$toast.success(response.dialog.message)
                            }
                        } else {
                            if (response.dialog != null && response.dialog.message != null) {
                                this.$toast.error(response.dialog.message)
                            }
                        }
                    }).catch((e) => {
                        setTimeout(this.getWebServers(source, true), 500);
                    });
            }
            return this.webServers;
        },
        getAccountings(source, forceGet) {
            if (source == 'created' && (forceGet || this.accountings == null || this.accountings.length == 0)) {
                Http()
                    .get(ENDPOINTS.accountings, {
                    })
                    .then((response) => {
                        if (response.status) {
                            this.accountings = response.data;
                            if (response.dialog != null && response.dialog.message != null) {
                                this.$toast.success(response.dialog.message)
                            }
                        } else {
                            if (response.dialog != null && response.dialog.message != null) {
                                this.$toast.error(response.dialog.message)
                            }
                        }
                    }).catch((e) => {
                        setTimeout(this.getAccountings(source, true), 500);
                    });
            }
            return this.accountings;
        },
        getComments(source, forceGet) {
            if (source == 'created' && (forceGet || this.accountings == null || this.accountings.length == 0)) {
                Http()
                    .get(ENDPOINTS.comments, {
                    })
                    .then((response) => {
                        if (response.status) {
                            this.comments = response.data;
                            if (response.dialog != null && response.dialog.message != null) {
                                this.$toast.success(response.dialog.message)
                            }
                        } else {
                            if (response.dialog != null && response.dialog.message != null) {
                                this.$toast.error(response.dialog.message)
                            }
                        }
                    }).catch((e) => {
                        setTimeout(this.getComments(source, true), 500);
                    });
            }
            return this.comments;
        },
        createComment() {
            if (this.checkIfEmpty(this.comment.name)) {
                this.$toast.error("Comment Is Invalid")
                return;
            }
            Http()
                .post(ENDPOINTS.createComment, {
                    commentValue: this.comment.name
                })
                .then((response) => {
                    if (response.status) {
                        this.comments.push({
                            id: response.data.id,
                            name: response.data.name
                        })
                        this.denomination.commentId = response.data.id
                        if (response.dialog != null && response.dialog.message != null) {
                            this.$toast.success(response.dialog.message)
                        }
                    } else {
                        if (response.dialog != null && response.dialog.message != null) {
                            this.$toast.error(response.dialog.message)
                        }
                    }
                })
                .catch((error) => {
                    this.$toast.error("Internal Server Error!")
                })
        },
        createAccounting() {
            if (this.checkIfEmpty(this.accounting.name)) {
                this.$toast.error("Accounting Is Invalid")
                return;
            }
            Http()
                .post(ENDPOINTS.createAccounting, {
                    accountingValue: this.accounting.name
                })
                .then((response) => {
                    if (response.status) {
                        this.accountings.push({
                            id: response.data.id,
                            name: response.data.name
                        })
                        this.denomination.accountingId = response.data.id
                        if (response.dialog != null && response.dialog.message != null) {
                            this.$toast.success(response.dialog.message)
                        }
                    } else {
                        if (response.dialog != null && response.dialog.message != null) {
                            this.$toast.error(response.dialog.message)
                        }
                    }
                })
                .catch((error) => {
                    this.$toast.error("Internal Server Error!")
                })
        },
        checkAccountingAndComment(){
            if (this.checkIfEmpty(this.denomination.commentId)) {
                this.$toast.error("Comment Is Invalid")
                return;
            }
            if (this.checkIfEmpty(this.denomination.id)) {
                this.$toast.error("Denomination Is Invalid")
                return;
            }
            if (this.checkIfEmpty(this.denomination.accountingId)) {
                this.$toast.error("Accounting Is Invalid")
                return;
            }
            Http()
                .post(ENDPOINTS.checkAccountingAndComment, {
                    denominationId: this.denomination.id,
                    commentId: this.denomination.commentId,
                    accountingId: this.denomination.accountingId
                })
                .then((response) => {
                    if (response.status) {
                        this.oldAccounting = response.data.accounting.name
                        this.oldComment = response.data.comment.name
                        this.oldReceiverAccountId = response.data.receiverAccountId
                        this.$refs.checkAccountingAndCommentBtn.click()
                        if (response.dialog != null && response.dialog.message != null) {
                            this.$toast.success(response.dialog.message)
                        }
                    } else {
                        this.$refs.checkAccountingAndCommentBtn.click()
                        if (response.dialog != null && response.dialog.message != null) {
                            this.$toast.error(response.dialog.message)
                        }
                    }
                })
                .catch((error) => {
                    this.$toast.error("Internal Server Error!")
                })
        },
        changeCommentAndAccounting() {
            if (this.checkIfEmpty(this.denomination.commentId)) {
                this.$toast.error("Comment Is Invalid")
                return;
            }
            if (this.checkIfEmpty(this.denomination.id)) {
                this.$toast.error("Denomination Is Invalid")
                return;
            }
            if (this.checkIfEmpty(this.denomination.accountingId)) {
                this.$toast.error("Accounting Is Invalid")
                return;
            }
            Http()
                .post(ENDPOINTS.changeCommentAndAccounting, {
                    denominationId: this.denomination.id,
                    commentId: this.denomination.commentId,
                    accountingId: this.denomination.accountingId,
                    receiverAccountId: this.denomination.receiverAccountId,
                    applicationType: this.applicationType
                })
                .then((response) => {
                    if (response.status) {
                        // this.getSchemaPrices();
                        this.showCreateSchemaPrice = true
                        this.$refs.closeCheckAccountingAndCommentModal.click()
                        if (response.dialog != null && response.dialog.message != null) {
                            this.$toast.success(response.dialog.message)
                        }
                    } else {
                        if (response.dialog != null && response.dialog.message != null) {
                            this.$toast.error(response.dialog.message)
                        }
                    }
                })
                .catch((error) => {
                    this.$toast.error("Internal Server Error!")
                })
        },
        createDenomination() {
            if (this.checkIfEmpty(this.denomination.name)) {
                this.$toast.error("Denomination Name Is Invalid")
                return;
            }
            if (this.checkIfEmpty(this.applicationType)) {
                this.$toast.error("Application Type Is Invalid")
                return;
            }
            if (((this.applicationType == "online") || (this.applicationType == "offline" && this.applicationSettlement == "Whish Money")) && this.checkIfEmpty(this.denomination.webServerId)) {
                this.$toast.error("Web Server Is Invalid")
                return;
            }
            if (this.applicationType == "offline" && this.applicationSettlement == "Whish App") {
                if (this.checkIfEmpty(this.denomination.accountingId)) {
                    this.$toast.error("Accounting Is Invalid")
                    return;
                }
                if (this.checkIfEmpty(this.denomination.commentId)) {
                    this.$toast.error("Comment Is Invalid")
                    return;
                }
            }
            if ((this.applicationType == "offline" && this.applicationSettlement == "Whish Money") || this.quicqpay){
                if (this.checkIfEmpty(this.denomination.externalId)) {
                    this.$toast.error((this.quicqpay ? 'Merchant Admin ID' : 'Whish Money Service ID') + " Is Invalid")
                    return;
                }
            }
            if (this.denomination.newImage && this.checkIfEmpty(this.denomination.imageName) && this.checkIfEmpty(this.denomination.imageData)) {
                this.$toast.error("Image Uploaded Is Invalid")
                return;
            }
            if (!this.denomination.newImage && this.checkIfEmpty(this.denomination.imageUrl)) {
                this.$toast.error("Image Selected Is Invalid")
                return;
            }

            Http()
                .post(ENDPOINTS.createDenomination, {
                    applicationType: this.applicationType,
                    name: this.denomination.name.toUpperCase(),
                    serviceId: this.selectedService,
                    imageName: this.denomination.imageName,
                    imageUrl: this.denomination.imageUrl,
                    imageData: this.denomination.imageData,
                    newImage: this.denomination.newImage,
                    collectionProviderExist: this.applicationSettlement != "Whish App",
                    settlement: this.applicationSettlement,
                    webServerId: this.denomination.webServerId,
                    bulkMultiBillPayment: this.denomination.bulkMultiBillPayment,
                    autoAcceptTopup: this.denomination.autoAcceptTopup,
                    commentId: this.denomination.commentId,
                    accountingId: this.denomination.accountingId,
                    externalId: this.denomination.externalId,
                    receiverAccountId: this.denomination.receiverAccountId,
                    quicqpay: this.quicqpay
                })
                .then((response) => {
                    if (response.status) {
                        var addedDenomination = {
                            id: response.data.denominationId,
                            name: response.data.name
                        }
                        this.denominations.push(addedDenomination)
                        this.newDenomination = false
                        this.denomination.id = response.data.denominationId
                        this.$refs.closeDenominationModal.click()
                        this.showCreateSchemaPrice = true
                        if (response.dialog != null && response.dialog.message != null) {
                            this.$toast.success(response.dialog.message)
                        }
                    } else {
                        if (response.dialog != null && response.dialog.message != null) {
                            this.$toast.error(response.dialog.message)
                        }
                        if (response.code == "fake.item.error") {
                            this.fakeItemFailed = true
                            var addedDenomination = {
                                id: response.data.denominationId,
                                name: response.data.name
                            }
                            this.showProviderMapping = true
                            this.denominations.push(addedDenomination)
                            this.newDenomination = false
                            this.denomination.id = response.data.denominationId
                            this.$refs.closeDenominationModal.click()
                        }
                    }
                })
                .catch((error) => {
                    this.$toast.error("Internal Server Error!")
                })
        },
        checkDenomination() {
            Http()
                .post(ENDPOINTS.checkDenomination, {
                    denominationId: this.denomination.id
                })
                .then((response) => {
                    if (response.status) {
                        if (this.applicationSettlement == "Whish App" || this.applicationType == "school" || this.quicqpay) {
                            this.showChangeCommentAndAccounting = true
                        } else {
                            this.denomination.flag ++
                            this.showCreateSchemaPrice = true
                            // this.getSchemaPrices()
                        }
                        if (response.dialog != null && response.dialog.message != null) {
                            this.$toast.success(response.dialog.message)
                        }
                        this.checkFakeItem();
                    } else {
                        if (response.dialog != null && response.dialog.message != null) {
                            this.$toast.error(response.dialog.message)
                        }
                    }
                })
                .catch((error) => {
                    this.$toast.error("Internal Server Error!")
                })
        },
        createFakeItem() {
            if (this.checkIfEmpty(this.denomination.id)) {
                this.$toast.error("Please Select A Denomination")
            } else {
                Http()
                    .post(ENDPOINTS.createFakeItem, {
                        denominationId: this.denomination.id,
                    })
                    .then((response) => {
                        if (response.status) {
                            this.fakeItemFailed = false
                            this.showProviderMapping = true
                            this.showCreateSchemaPrice = false
                            if (this.applicationSettlement == "Whish App") {
                                this.showCreateSchemaPrice = true
                            }
                            if (response.dialog != null && response.dialog.message != null) {
                                this.$toast.success(response.dialog.message)
                            }
                        } else {
                            if (response.dialog != null && response.dialog.message != null) {
                                this.$toast.error(response.dialog.message)
                            }
                        }
                    })
                    .catch((error) => {
                        this.$toast.error("Internal Server Error!")
                    })
            }
        },
        checkFakeItem() {
            Http()
                .post(ENDPOINTS.checkFakeItem, {
                    denominationId: this.denomination.id
                })
                .then((response) => {
                    if (response.status) {
                        if (response.dialog != null && response.dialog.message != null) {
                            this.$toast.success(response.dialog.message)
                        }
                    } else {
                        if (response.dialog != null && response.dialog.message != null) {
                            this.$toast.error(response.dialog.message)
                        }
                        if (response.code == "fake.item.error") {
                            this.fakeItemFailed = true
                            this.providerMappingFailed = true;
                            if (this.applicationType == "offline") {
                                this.showCreateApplication = false
                            } else if (this.applicationType == "online") {
                                this.showCreateMenuItem = false
                            }
                        }
                    }
                })
                .catch((error) => {
                    this.$toast.error("Internal Server Error!")
                })
        },
        changeShowCreateSchemaPrice(selected) {
            this.showCreateSchemaPrice = selected
        },
        checkReceiverAccountId(){
            if (this.checkIfEmpty(this.denomination.receiverAccountId)){
                this.$toast.error("Invalid Receiver Account ID!")
                return
            }
            Http()
                .get(ENDPOINTS.checkReceiverAccountId + "/" + this.denomination.receiverAccountId, {
                })
                .then((response) => {
                    if (response.status) {
                        this.receiverAccountChecked = true
                        if (response.dialog != null && response.dialog.message != null) {
                            this.$toast.success(response.dialog.message)
                        }
                    } else {
                        if (response.dialog != null && response.dialog.message != null) {
                            this.$toast.error(response.dialog.message)
                        }
                    }
                })
                .catch((error) => {
                    this.$toast.error("Internal Server Error!")
                })
        },
    }
}
</script>
<style scoped>

.subTitle{
  font-size: 23px;
  font-weight: 500;
}

.btn-primary{
  background-color: #E40046!important;
}

input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button {  

   opacity: 1;

}
</style>
