<template >
  <div class="mx-5">
    <div class="row my-3">
      <div class="col-6">
        <label class="title mt-2">Welcome <small>{{ this.$store.state.user.name }}</small></label>
      </div>
      <div class="col-3" v-if="this.$store.state.privileges.SwitchServer">
        <button class="bg-gray-500  text-white font-bold py-2 px-4 rounded inline-flex items-center shadow-sm text-sm float-right mt-3" @click="openSwitchServer">Switch Server</button>
      </div>
      <div class="col-12" v-if="showAuthenticationBtn">
        <button class="bg-gray-500  text-white font-bold py-2 px-4 rounded inline-flex items-center shadow-sm text-sm float-right mt-3" @click="showAuthentication = true">Multi-Factor Authentication</button>
      </div>
    </div>
    <div class="row">
      <router-link to="/applications" v-if="this.$store.state.privileges.Applications" class="col-2">
        <div class="box bg-woo" style="width: 160px; height: 90px;">
          <label>Applications</label>
          <br />
          <div class="text-end me-3" style="float: right; margin-top: 15px; margin-right: 15px;" >
            <DocumentDuplicateIcon />
          </div>
        </div>
      </router-link>
      <router-link to="/collect" v-if="this.$store.state.privileges.Collect" class="col-2">
        <div class="box bg-woo" style="width: 160px; height: 90px;">
          <label>Collect</label>
          <br />
          <div class="text-end me-3" style="float: right; margin-top: 15px; margin-right: 15px;">
            <HandIcon />
          </div>
        </div>
      </router-link>
      <router-link to="/collections" v-if="this.$store.state.privileges.Collections" class="col-2">
        <div class="box bg-woo" style="width: 160px; height: 90px;">
          <label>Collections</label>
          <br />
          <div class="text-end me-3" style="float: right; margin-top: 15px; margin-right: 15px;">
            <CollectionIcon />
          </div>
        </div>
      </router-link>
      <router-link to="/payments" v-if="this.$store.state.privileges.Payments" class="col-2">
        <div class="box bg-woo" style="width: 160px; height: 90px;">
          <label>Payments</label>
          <br />
          <div class="text-end me-3" style="float: right; margin-top: 15px; margin-right: 15px;">
            <CurrencyDollarIcon />
          </div>
        </div>
      </router-link>
      <router-link to="/active-accounts" v-if="this.$store.state.privileges.ActiveAccounts" class="col-2">
        <div class="box bg-woo" style="width: 160px; height: 90px;">
          <label>Active Accounts</label>
          <br />
          <div class="text-end me-3" style="float: right; margin-top: 15px; margin-right: 15px;">
            <IdentificationIcon />
          </div>
        </div>
      </router-link>
      <router-link to="/balance-statement" v-if="this.$store.state.privileges.BalanceStatement" class="col-2">
        <div class="box bg-woo" style="width: 160px; height: 90px;">
          <label>Balance Statement</label>
          <br />
          <div class="text-end me-3" style="float: right; margin-top: 15px; margin-right: 15px;">
            <ScaleIcon />
          </div>
        </div>
      </router-link>
      <router-link to="/sales" v-if="this.$store.state.privileges.Sales" class="col-2">
        <div class="box bg-woo" style="width: 160px; height: 90px;">
          <label>Sales</label>
          <br />
          <div class="text-end me-3" style="float: right; margin-top: 15px; margin-right: 15px;">
            <ChartBarIcon />
          </div>
        </div>
      </router-link>
      <router-link to="/statement" v-if="this.$store.state.privileges.Statement" class="col-2">
        <div class="box bg-woo" style="width: 160px; height: 90px;">
          <label>Statement</label>
          <br />
          <div class="text-end me-3" style="float: right; margin-top: 15px; margin-right: 15px;">
            <ChartSquareBarIcon />
          </div>
        </div>
      </router-link>
      <router-link to="/collection-report" v-if="this.$store.state.privileges.CollectionApi" class="col-2">
        <div class="box bg-woo" style="width: 160px; height: 90px;">
          <label>Collection Report</label>
          <br />
          <div class="text-end me-3" style="float: right; margin-top: 15px; margin-right: 15px;">
            <CollectionIcon />
          </div>
        </div>
      </router-link>
      <router-link to="/create-collection-application" v-if="this.$store.state.privileges.CreateCollectionApplication" class="col-2">
        <div class="box bg-woo" style="width: 160px; height: 90px;">
          <label>Create Application</label>
          <br />
          <div class="text-end me-3" style="float: right; margin-top: 15px; margin-right: 15px;">
            <CollectionIcon />
          </div>
        </div>
      </router-link>
      <router-link to="/online-payments" v-if="this.$store.state.privileges.OnlinePayments" class="col-2">
        <div class="box bg-woo" style="width: 160px; height: 90px;">
          <label>Online Payments</label>
          <br />
          <div class="text-end me-3" style="float: right; margin-top: 15px; margin-right: 15px;" >
            <CashIcon />
          </div>
        </div>
      </router-link>
      <router-link to="/school-configuration" v-if="this.$store.state.privileges.SchoolConfiguration" class="col-2">
        <div class="box bg-woo" style="width: 160px; height: 90px;">
          <label>School Configuration</label>
          <br />
          <div class="text-end me-3" style="float: right; margin-top: 15px; margin-right: 15px;" >
            <UserGroupIcon />
          </div>
        </div>
      </router-link>
      <router-link to="/monty-pay" v-if="this.$store.state.privileges.MontyPay" class="col-2">
        <div class="box bg-woo" style="width: 160px; height: 90px;">
          <label>Monty Pay</label>
          <br />
          <div class="text-end me-3" style="float: right; margin-top: 15px; margin-right: 15px;" >
            <CashIcon />
          </div>
        </div>
      </router-link>
    </div>
    <MultiFactorAuthentication v-if="showAuthentication" @changeShowAuthenticationBtn="changeShowAuthenticationBtn" @close="showAuthentication = false"  />
    <switch-server-modal ref="openSwitchServerModal" />
  </div>
</template>
<script>
import {
  CollectionIcon,
  IdentificationIcon,
  DocumentDuplicateIcon,
  CurrencyDollarIcon,
  HandIcon,
  ScaleIcon,
  ChartBarIcon,
  LockOpenIcon,
  ChartSquareBarIcon,
  CashIcon,
  UserGroupIcon,
} from "@vue-hero-icons/outline";

import MultiFactorAuthentication from "../components/MultiFactorAuthentication";
import SwitchServerModal from '../components/SwitchServerModal.vue';

export default {
  components: {
    CollectionIcon,
    IdentificationIcon,
    DocumentDuplicateIcon,
    CurrencyDollarIcon,
    HandIcon,
    ScaleIcon,
    ChartBarIcon,
    MultiFactorAuthentication,
    ChartSquareBarIcon,
    CashIcon,
    SwitchServerModal,
    UserGroupIcon,
  },
  data(){
    return{
      showAuthentication: false,
      showAuthenticationBtn: false
    }
  },
  created(){
    this.showAuthenticationBtn = !this.$store.state.user.authenticatorStatus
    let that = this;
    document.addEventListener("keyup", function (evt) {
      if (evt.keyCode === 27) {
        that.close();
      }
    });
  },
  methods: {
    changeShowAuthenticationBtn(val){
      this.showAuthenticationBtn = val
    },
    openSwitchServer(){
      this.$refs.openSwitchServerModal.$refs.switchServerModalBtn.click()

    }
  }
};
</script>
<style scoped>
.title {
  font-size: 1.5em;
}

.active {
  color: white !important;
}

a {
  color: white !important;
}

a:hover {
  color: black !important;
  text-decoration: none !important;
}

button:hover {
  color: white;
  background-color: transparent !important;
}

.box {
  color: white;
  cursor: pointer;
  transition: .4s;
  margin-right: 15px;
  margin-bottom: 15px;
  text-align: center;
}


.box:hover {
  box-shadow: 0px 0px 10px 1px rgba(125, 125, 125, 0.75);
  transform: scale(1.1);
  transition: .4s;
}
</style>
