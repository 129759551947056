<template>
  <div id="app" class="h-screen">
    <vue-internet-checker @status="status" />
    <div name="sidebar" v-if="$route.name != 'Login' && $route.name != 'PasswordReset'">
      <Navbar />
    </div>
    <router-view @loggedIn="goToMainPage()" />
    <div id="modalLocation"></div>
    <Loader v-if="this.loading" />
    <slot v-else></slot>
  </div>
</template>

<script>
import router from "./router";
import Loader from "./components/Loader.vue";
import Navbar from "./components/Navbar.vue";
import Vue from "vue";
import { mapState } from "vuex";
import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";
import vueInternetChecker from "vue-internet-checker";

Vue.use(Toast, {
  // registration props here
});
export default {
  data() {
    return {
      online: null,
    };
  },
  components: {
    Loader,
    vueInternetChecker,
    Navbar,
  },
  computed: {
    ...mapState("loader", ["loading"]),
  },
  created() {
    // Clear pending requests and turn off loadder on refresh
    this.$store.state.loader.loading = false;
    this.$store.state.loader.requestsPending = 0;
    if(this.$store.state.privileges == null || this.$store.state.privileges.length == 0){
      this.$store.commit("logout");
    }
  },
  methods: {
    status(ele) {
      this.online = ele;
      if (this.online) {
        this.$toast.success("Internet connection was restored");
      } else {
        this.$toast.error("You are currently offline");
      }
    },
    goToMainPage() {
      router.push("/");
    },
    hideOverflow(hide) {
      document.getElementById("app").style.overflow = hide ? "hidden" : "auto";
    },
  },
};
</script>
<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}
</style>
<style scoped>
body {
  font-size: 0.9rem;
}
</style>
