<template>
    <div class="mx-5 py-5" style="text-align: left !important">
        <div class="row">
            <div class="col-12 mt-2">
                <label class="title">Reset Password</label>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <p>
                    Your password should be strong with at least 12 characters long, include a mix of uppercase and
                    lowercase letters, numbers, and special characters, and avoid using easily guessable information.
                </p>
            </div>
            <div class="col-6">
                <div class="mb-3">
                    <label class="form-label">Old Passowrd <span class="text-danger">*</span></label>
                    <input type="password" class="form-control" v-model="oldPassword" />
                </div>
            </div>
            <div class="col-6"></div>
            <div class="col-6">
                <div class="mb-3 position-relative">
                    <label class="form-label">New Passowrd <span class="text-danger">*</span></label>
                    <input :type="newPasswordFieldType" class="form-control" v-model="password" />
                    <button v-if="newPasswordFieldType === INPUT_TYPE_PASSWORD" type="button" class="eyeClass" @click="togglePasswordVisibility(false, INPUT_TYPE_TEXT)"><i class="bi bi-eye-slash"></i></button>
                    <button v-if="newPasswordFieldType === INPUT_TYPE_TEXT" type="button" class="eyeClass" @click="togglePasswordVisibility(false, INPUT_TYPE_PASSWORD)"><i class="bi bi-eye"></i></button>
                </div>
            </div>
            <div class="col-6">
                <div class="mb-3 position-relative">
                    <label class="form-label">Confirm Passowrd <span class="text-danger">*</span></label>
                    <input :type="confirmPasswordFieldType" class="form-control" v-model="confirmPassword" />
                    <button v-if="confirmPasswordFieldType === INPUT_TYPE_PASSWORD" type="button" class="eyeClass" @click="togglePasswordVisibility(true, INPUT_TYPE_TEXT)"><i class="bi bi-eye-slash"></i></button>
                    <button v-if="confirmPasswordFieldType === INPUT_TYPE_TEXT" type="button" class="eyeClass" @click="togglePasswordVisibility(false, INPUT_TYPE_PASSWORD)"><i class="bi bi-eye"></i></button>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-12 text-end">
                <button type="button" class="btn btn-primary ms-3" @click="submitButton">Submit</button>
            </div>
        </div>
    </div>
</template>
<script>
import Http, { ENDPOINTS } from "../request";
import { commonFunctions } from "../helpers/CommonFunctions"
import store from "../store";
import router from "../router";
export default {
    data() {
        return {
            INPUT_TYPE_PASSWORD: "password",
            INPUT_TYPE_TEXT: "text",
            checkIfEmpty: commonFunctions.checkIfEmpty,
            newPasswordFieldType: "password",
            confirmPasswordFieldType: "password",
            password: null,
            confirmPassword: null,
            oldPassword: null
        }
    },
    methods: {
        submitButton() {
            if (this.checkIfEmpty(this.oldPassword)) {
                this.$toast.error("Old Password Has Invalid Value");
                return;
            }
            if (this.checkIfEmpty(this.password)) {
                this.$toast.error("New Password Has Invalid Value");
                return;
            }
            if (this.checkIfEmpty(this.confirmPassword)) {
                this.$toast.error("Confirm Password Has Invalid Value");
                return;
            }
            if (this.password != this.confirmPassword) {
                this.$toast.error("Passwords Do Not Match");
                return;
            }
            Http()
                .post(ENDPOINTS.resetPassword, {
                    username: this.$route.params.username,
                    password: this.password,
                    oldPassword: this.oldPassword
                })
                .then((response) => {
                    if (response.status) {
                        router.push({ name: 'Login' })
                        if (response.dialog != null && response.dialog.message != null) {
                            this.$toast.success(response.dialog.message)
                        }
                    } else {
                        if (response.dialog != null && response.dialog.message != null) {
                            this.$toast.error(response.dialog.message)
                        }
                    }
                })
                .catch((error) => {
                    this.$toast.error("Internal Server Error!")
                })
        },
        togglePasswordVisibility(confirm, type) {
            if (confirm){
                this.confirmPasswordFieldType = type
            } else {
                this.newPasswordFieldType = type ;
            }
        }

    }
}
</script>

<style scoped>
.title {
    font-size: 30px;
    font-weight: bold;
}

.eyeClass{
    position: absolute;
    right: 3%;
    top: 57%;
}
</style>