<template>
    <div class="mx-5 py-5">
        <div class="row mb-3">
            <div class="col-9">
                <h2 class="text-2xl font-bold leading-7 text-gray-900 sm:text-3xl sm:truncate">
                    {{ title }}
                </h2>
            </div>
            <div class="col-3">
                <vue-excel-xlsx
                    class="bg-green-500 hover:bg-gray-400 text-white font-bold py-2 px-4 rounded shadow-sm text-sm float-right"
                    :data="data" :columns="columns" :file-name="title + new Date().getTime().toString()"
                    :file-type="'xlsx'" :sheet-name="title">
                    Export
                </vue-excel-xlsx>
                <button
                    class="appearance-none  bg-woo shadow text-white rounded py-2 px-4  leading-tight float-right me-2"
                    @click="getReport">Search</button>
            </div>
        </div>
        <div class="row">
            <div class="col-4"></div>
            <div class="col-4 d-inline-flex app">
                <label style="margin: auto 5px;">From:</label>
                <date-time-picker v-model="dateFrom" format="dd-LL-yyyy HH:mm:ss"
                    :initial-view-date="this.dateFrom"></date-time-picker>
            </div>
            <div class="col-4  d-inline-flex app">
                <label style="margin: auto 5px;">To:</label>
                <date-time-picker v-model="dateTo" format="dd-LL-yyyy HH:mm:ss"
                    :initial-view-date="this.dateTo"></date-time-picker>
            </div>
        </div>
        <div class="row" v-if="data != null && data.length != 0">
            <div class="col-12">
                <GoodTable :name="title" :rows="data" :columns="columns" :line-numbers="true" />
            </div>
        </div>
        <div v-else>
            <div
                class="container flex flex-col items-center justify-center px-5 mx-auto my-8 space-y-8 text-center sm:max-w-md">
                <ExclamationIcon class="w-32 h-32 text-gray-900"></ExclamationIcon>
                <p class="text-3xl">No Data Found!</p>
            </div>
        </div>
    </div>
</template>

<script>
import Http, { ENDPOINTS } from "../request";
import GoodTable from "../components/GoodTable.vue";
import DateTimePicker from "vue-vanilla-datetime-picker";
import "../../node_modules/vue-vanilla-datetime-picker/dist/DateTimePicker.css";
import { ExclamationIcon } from "@vue-hero-icons/outline";
import { commonFunctions } from "../helpers/CommonFunctions";
export default {
    components: {
        DateTimePicker,
        GoodTable,
        ExclamationIcon,
    },
    created() {
        this.filtersHandler();
    },
    data() {
        return {
            title: "Monty Pay",
            formatNumber: commonFunctions.formatNumber,
            dateFrom: "",
            dateTo: "",
            data: [],
            columns: [
                {
                    label: "Transaction ID",
                    field: "transactionId",
                    index: 1,
                    filterOptions: {
                        enabled: true,
                        placeholder: "Search by Transaction ID",
                        filterValue: "",
                    },
                },
                {
                    label: "Transaction Type",
                    field: "type",
                    index: 10,
                    filterOptions: {
                        enabled: true,
                        placeholder: "Search by Type",
                        filterValue: "",
                    },
                },
                {
                    label: "Gross Amount",
                    field: "grossAmount",
                    index: 20,
                    formatFn: f => (this.formatNumber(f)),
                    filterOptions: {
                        enabled: true,
                        placeholder: "Search by Gross Amount",
                        filterValue: "",
                    },
                },
                {
                    label: "Tip Amount",
                    field: "tipAmount",
                    index: 30,
                    formatFn: f => (this.formatNumber(f)),
                    filterOptions: {
                        enabled: true,
                        placeholder: "Search by Tip Amount",
                        filterValue: "",
                    },
                },
                {
                    label: "Total Amount",
                    field: "totalAmount",
                    index: 40,
                    formatFn: f => (this.formatNumber(f)),
                    filterOptions: {
                        enabled: true,
                        placeholder: "Search by Total Amount",
                        filterValue: "",
                    },
                },
                {
                    label: "Currency",
                    field: "currency",
                    index: 50,
                    filterOptions: {
                        enabled: true,
                        placeholder: "Search by Currency",
                        filterValue: "",
                    },
                },
                {
                    label: "Merchant Name",
                    field: "merchantName",
                    index: 60,
                    filterOptions: {
                        enabled: true,
                        placeholder: "Search by Merchant Name",
                        filterValue: "",
                    },
                },
                {
                    label: "Payment Type",
                    field: "paymentType",
                    index: 70,
                    filterOptions: {
                        enabled: true,
                        placeholder: "Search by Payment Type",
                        filterValue: "",
                    },
                },
                {
                    label: "Payment Brand",
                    field: "paymentBrand",
                    index: 80,
                    filterOptions: {
                        enabled: true,
                        placeholder: "Search by Payment Brand",
                        filterValue: "",
                    },
                },
                {
                    label: "MCC Description",
                    field: "mccDescription",
                    index: 90,
                    filterOptions: {
                        enabled: true,
                        placeholder: "Search by MCC Description",
                        filterValue: "",
                    },
                },
                {
                    label: "Reference ID",
                    field: "referenceId",
                    index: 100,
                    filterOptions: {
                        enabled: true,
                        placeholder: "Search by Reference ID",
                        filterValue: "",
                    },
                },
                {
                    label: "TID",
                    field: "tid",
                    index: 110,
                    filterOptions: {
                        enabled: true,
                        placeholder: "Search by TID",
                        filterValue: "",
                    },
                },
                {
                    label: "MID",
                    field: "mid",
                    index: 120,
                    filterOptions: {
                        enabled: true,
                        placeholder: "Search by MID",
                        filterValue: "",
                    },
                },
                {
                    label: "Processing Date",
                    field: "processingDate",
                    index: 130,
                },
            ]
        }
    },
    methods: {
        filtersHandler() {
            this.dateTo = new Date();
            this.dateFrom = new Date(this.dateTo - 1);
            this.dateTo.setHours(23, 59, 59, 59);
            this.dateFrom.setHours(0, 0, 0, 0);
        },
        getReport() {
            Http()
                .get(ENDPOINTS.montyPayReport, {
                    dateTo: new Date(this.dateTo).getTime(),
                    dateFrom: new Date(this.dateFrom).getTime(),
                })
                .then((response) => {
                    if (response.status) {
                        this.data = response.data;
                        if (response.dialog != null && response.dialog.message != null) {
                            this.$toast.success(response.dialog.message)
                        }
                    } else {
                        if (response.dialog != null && response.dialog.message != null) {
                            this.$toast.error(response.dialog.message)
                        }
                    }
                })
                .catch((error) => {
                    this.$toast.error("Internal Server Error!")
                })
        },
    }
}
</script>

<style></style>