<template>
    <div>
        <div class="row mt-3">
            <div class="col-12">
                <label class="subTitle mb-3"><i class="bi bi-dash me-2"></i>{{"Application And Application Fields" + (applicationType == "online" ? " (OPTIONAL)" : "")}}</label>
            </div>
            <div class="col-6">
                <div class="mb-3 form-check">
                    <input type="radio" :value="false" name="newApplication" class="form-check-input" id="oldApplication" v-model="newApplication" />
                    <label class="form-check-label" for="oldApplication">Choose Existing Application</label>
                </div>
            </div>
            <div class="col-6">
                <div class="mb-3 form-check">
                    <input type="radio" name="newApplication" class="form-check-input" id="newApplication" v-model="newApplication" :value="true" data-bs-toggle="modal" data-bs-target="#newApplicationModal" />
                    <label class="form-check-label" for="newApplication">Create Application</label>
                </div>
            </div>
            <div v-if="newApplication != null && !newApplication">
                <div class="row">
                    <div class="col-4">
                        <div class="mb-3 d-inline-flex w-100">
                            <v-select :options="collectionQuicqpayApplications == null ? [] : collectionQuicqpayApplications" label="name" class="vselect w-100" placeholder="Choose Application" v-model="selectedApplication">
                            <template slot="option" slot-scope="option" >
                                <span class="pl-4">{{ option.name }}</span>
                            </template>
                            </v-select>
                            <button type="button" class="btn btn-secondary ms-1" @click="getcollectionQuicqpayApplications('created', true)"><i class="bi bi-arrow-clockwise"></i></button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-6" v-if="allowAddApplicationField">
                <div class="mb-3 form-check">
                    <input type="radio" @click="editApplicationField = false" name="newApplicationField" class="form-check-input" id="newApplicationField" v-model="newApplicationField" :value="true" data-bs-toggle="modal" data-bs-target="#newApplicationFieldModal" />
                    <label class="form-check-label" for="newApplicationField">Create Application Field</label>
                </div>
            </div>
        </div>
        <div class="modal fade" id="newApplicationModal" tabindex="-1" aria-labelledby="newApplicationModal" aria-hidden="true">
            <div class="modal-dialog modal-xl">
                <div class="modal-content">
                    <div class="modal-header">
                        <label class="modal-title subTitle">Create Application</label>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                    <div class="modal-body">
                        <div class="container-fluid">
                            <div class="row">
                                <div class="col-4">
                                    <div class="mb-3">
                                    <label class="form-label">Title <span class="text-danger">*</span></label>
                                    <input type="text" class="form-control" v-model="application.title" />
                                    </div>
                                </div>
                                <div class="col-4">
                                    <div class="mb-3">
                                    <label class="form-label">Tags <span class="text-danger">*</span></label>
                                    <vue-multi-select style="width: 100%; text-align: start" ref="multiSelect" v-model="application.tags" search 
                                    :options="options" :filters="filters" :btnLabel="btnLabel" :selectOptions="tags">
                                    </vue-multi-select>
                                    </div>
                                </div>
                                <div class="col-4">
                                    <div class="mb-3">
                                    <label class="form-label">Account ID <small class="text-danger">{{applicationAccount.name}}</small></label>
                                    <input @blur="accountSearch" type="number" class="form-control" v-model="applicationAccount.id" />
                                    </div>
                                </div>
                            </div>
                            <div class="row" v-if="!quicqpay">
                                <div class="col-12">
                                    <div class="row">
                                        <div class="col-12 mb-3">
                                            <label class="subTitle">Automated Application Field Information</label>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-12">
                                            <AppField v-model="fields['payerFirstName']" :field="fields['payerFirstName']" :stringFields="stringFields" :getStringFields="getStringFields"/>
                                            <AppField v-model="fields['payerLastName']" :field="fields['payerLastName']" :stringFields="stringFields" :getStringFields="getStringFields"/>
                                            <AppField v-model="fields['payerPhone']" :field="fields['payerPhone']" :stringFields="stringFields" :getStringFields="getStringFields"/>
                                            <AppField v-model="fields['amount']" :field="fields['amount']" :stringFields="stringFields" :getStringFields="getStringFields"/>
                                            <AppField v-model="fields['feesAmount']" :field="fields['feesAmount']" :stringFields="stringFields" :getStringFields="getStringFields"/>
                                            <AppField v-model="fields['receivableAmount']" :field="fields['receivableAmount']" :stringFields="stringFields" :getStringFields="getStringFields"/>
                                            <div class="col-4">
                                                <div class="mb-3 form-check">
                                                    <input type="checkbox" class="form-check-input" id="applicationIsReference" v-model="applicationIsReference"/>
                                                    <label class="form-check-label" for="applicationIsReference">Payer Reference</label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row" v-if="applicationIsReference">
                                    <AppField v-if="applicationIsReference" v-model="fields['payerReference']" :field="fields['payerReference']" :stringFields="stringFields"/>
                                    <div class="col-12" >
                                        <div class="mb-3">
                                            <label class="subTitle" style="font-size: 20px;">Payer Reference Input Information</label>
                                        </div>
                                    </div>
                                    <div class="col-12">
                                        <FieldTypeData :applicationFieldTypes="applicationFieldTypes" :applicationFieldData="fields['payerReference'].fieldData" :applicationFields="applicationFields" :getApplicationFieldTypes="getApplicationFieldTypes" :getApplicationFieldsByApplicationId="getApplicationFieldsByApplicationId"/>
                                    </div>
                                </div>
                                <div class="row" v-if="applicationSettlement == 'Whish Money'">
                                    <div class="col-12">
                                        <AppField  v-model="fields['reasonId']" :field="fields['reasonId']" :stringFields="stringFields"/>
                                    </div>
                                    <div class="col-4">
                                        <div class="mb-3">
                                            <label class="form-label">Whish Money Reason ID <span class="text-danger">*</span></label>
                                            <input type="text" class="form-control" v-model="fields['reasonId'].fieldData.defaultValue" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" ref="closeApplicationModal" data-bs-dismiss="modal" @click="newApplication = false">Close</button>
                        <button type="button" class="btn btn-primary"  @click="createApplication">Create Application</button>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal fade" id="newApplicationFieldModal" tabindex="-1" aria-labelledby="newApplicationFieldModal" aria-hidden="true">
            <div class="modal-dialog modal-xl">
                <div class="modal-content">
                    <div class="modal-header">
                    <label class="modal-title subTitle">{{editApplicationField ? "Edit " : "Create "}}Application Field</label>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                        <div class="container-fluid">
                            <AppField v-model="applicationField" :field="applicationField" :stringFields="stringFields" :getStringFields="getStringFields"/>
                            <div class="row">
                                <div class="col-4">
                                    <div class="mb-3">
                                    <label class="form-label">Identifier <span class="text-danger">*</span></label>
                                    <input type="text" class="form-control" v-model="applicationField.identifier" />
                                    </div>
                                </div>
                                <div class="col-4">
                                    <div class="mb-3">
                                    <label class="form-label">Index <span class="text-danger">*</span></label>
                                    <input type="number" class="form-control" v-model="applicationField.index" />
                                    </div>
                                </div>
                            </div>
                            <FieldTypeData :applicationFieldTypes="applicationFieldTypes" :applicationFieldData="applicationField.fieldData" :applicationFields="applicationFields" :getApplicationFieldTypes="getApplicationFieldTypes" :getApplicationFieldsByApplicationId="getApplicationFieldsByApplicationId"/>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" ref="closeApplicationFieldModal" data-bs-dismiss="modal" @click="newApplicationField = false">Close</button>
                        <button type="button" class="btn btn-primary"  @click="createApplicationField">{{ editApplicationField ? "Edit " : "Create " }} Application Field</button>
                    </div>
                </div>
            </div>
        </div>
        <div v-if="applicationFields.length > 0">
            <div class="row">
                <div class="col-12">
                    <label class="subTitle mb-2" style="font-size: 20px">Available Fields:</label>
                    <table class="table table-bordered">
                        <thead>
                            <tr>
                                <th>Title</th>
                                <th>Type</th>
                                <th>Identifier</th>
                                <th>Index</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="field in applicationFields" :key="field.id">
                                <td>{{field.title}}</td>
                                <td>{{field.type}}</td>
                                <td>{{field.identifier}}</td>
                                <td>{{field.index}}</td>
                                <td class="text-center">
                                    <input type="radio" name="newApplicationField" class="form-check-input d-none" id="editApplicationField" v-model="newApplicationField" :value="true" data-bs-toggle="modal" data-bs-target="#newApplicationFieldModal"/>
                                    <label class="btn btn-primary" for="editApplicationField" @click="updateApplicationField(field)">Edit</label>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <label class="subTitle mb-2" style="font-size: 20px">Receipt Data:</label>
                </div>
                <div class="col-6">
                    <div class="mb-3 form-check">
                        <input type="radio" name="receiptData" class="form-check-input" id="receiptData" :value="true" data-bs-toggle="modal" data-bs-target="#receiptDataModal" />
                        <label class="form-check-label" for="receiptData">Set Receipt Data Information</label>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal fade" id="receiptDataModal" tabindex="-1" aria-labelledby="receiptDataModal" aria-hidden="true">
            <div class="modal-dialog modal-lg">
                <div class="modal-content">
                    <div class="modal-header">
                    <label class="modal-title subTitle">Receipt Data</label>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                        <div class="container-fluid">
                            <div class="row">
                                <div class="col-6">
                                    <label class="form-label">Identifiers:</label>
                                </div>
                                <div class="col-6">
                                    <label class="form-label">Display Names:</label>
                                </div>
                            </div>
                            <div class="row" v-for="(d, index) in receiptData" :key="index">
                                <div class="col-6">
                                    <input type="text" class="form-control mb-2" v-model="d.identifier" disabled />
                                </div>
                                <div class="col-6">
                                    <input type="text" class="form-control mb-2" v-model="d.displayName" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" ref="closeReceiptDataModal" data-bs-dismiss="modal">Close</button>
                        <button type="button" class="btn btn-primary"  @click="setReceiptData">Confirm</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import vueMultiSelect from 'vue-multi-select';
import 'vue-multi-select/dist/lib/vue-multi-select.css';
import FieldTypeData from "../FieldTypeData.vue";
import AppField from '../ApplicationField.vue';
import Http, { ENDPOINTS } from "../../request";
import {commonFunctions} from "../../helpers/CommonFunctions"
export default {
    components:{
        vSelect,
        vueMultiSelect,
        FieldTypeData,
        AppField
    },
    props:{
        applicationSettlement: String,
        applicationType: String,
        denomination: Object,
        application: Object,
        quicqpay: Boolean
    },
    data(){
        return{
            checkIfEmpty: commonFunctions.checkIfEmpty,
            btnLabel: values => `Select (${values.length})`,
            filters: [{
                nameAll: 'Select all',
                nameNotAll: 'Deselect all',
                func() {
                return true;
                },
            }],
            options: {
                multi: true,
                groups: false,
                labelName: "label",
                labelValue: "id" ,
                cssSelected: option => (option.selected ? { 'color': '#E40046' } : ''),
            },
            newApplication: null,
            selectedApplication: null,
            newApplicationField: null,
            applicationIsReference: false,
            stringFields: null,
            collectionQuicqpayApplications: null,
            applicationAccount: {
                id: null,
                name: null,
            },
            fields: {
                "payerFirstName": {
                    "label": "Payer First Name",
                    "newHint": false,
                    "identifier": "payerfirstname",
                    "hintFromOldKey": "field.cp.payer.first",
                    "enHint": "",
                    "arHint": "",
                    "index": "1",
                    "fieldData": {
                        "type": "text",
                    }
                },
                "payerLastName": {
                    "label": "Payer Last Name",
                    "newHint": false,
                    "identifier": "payerlastname",
                    "hintFromOldKey": "field.cp.payer.last",
                    "enHint": "",
                    "arHint": "",
                    "index": "2",
                    "fieldData": {
                        "type": "text",
                    }
                },
                "payerPhone": {
                    "label": "Payer Phone",
                    "newHint": false,
                    "identifier": "payerPhone",
                    "hintFromOldKey": "field.cp.payer.phone",
                    "enHint": "",
                    "arHint": "",
                    "index": "3",
                    "fieldData": {
                        "type": "text",
                    }
                },
                "amount": {
                    "label": "Amount",
                    "newHint": false,
                    "identifier": "amount",
                    "hintFromOldKey": this.$store.state.server.id == 1 ? "field.kva.amount.pay.lbp" : "field.collection.amount.pay.usd",
                    "enHint": "",
                    "arHint": "",
                    "index": "4",
                    "fieldData": {
                        "type": "text",
                    }
                },
                "feesAmount": {
                    "label": "Fees Amount",
                    "newHint": false,
                    "identifier": "feesAmount",
                    "hintFromOldKey": this.$store.state.server.id == 1 ? "field.collect.fees.lbp" : "field.collect.fees.usd",
                    "enHint": "",
                    "arHint": "",
                    "index": "5",
                    "fieldData": {
                        "type": "equation",
                    }
                },
                "receivableAmount": {
                    "label": "Receivable Amount",
                    "newHint": false,
                    "identifier": "receivableamount",
                    "hintFromOldKey": this.$store.state.server.id == 1 ? "field.lda.total_amount" : "field.lda.total_amount",
                    "enHint": "",
                    "arHint": "",
                    "index": "6",
                    "fieldData": {
                        "type": "equation",
                    }
                },
                "payerReference": {
                    "label": "Payer Reference",
                    "newHint": false,
                    "identifier": "payerReference",
                    "hintFromOldKey": null,
                    "enHint": "",
                    "arHint": "",
                    "index": "7",
                    "fieldData": {
                        type: null,
                        spinnerObject: null,
                        applicationFieldTextValueString: null,
                        spinnerPrompt: null,
                        inputType: null,
                        retrieveSavedContact: false,
                        minNumber: null,
                        maxNumber: null,
                        applicationFieldMinCharacter: null,
                        maxChar: null,
                        wrongInputHint: null,
                        radioSpanCount: null,
                        hint: null,
                        checkboxStylePopup: false,
                        dateFormat: "dd-MM-yyyy",
                        dependencies: [],
                    }
                },
                "reasonId": {
                    "label": "Reason Id",
                    "newHint": false,
                    "identifier": "reasonId",
                    "hintFromOldKey": "field.reason.payment_type",
                    "enHint": "",
                    "arHint": "",
                    "index": "8",
                    "fieldData": {
                        "defaultValue": null,
                        "type": "hidden"
                    }
                }
            },
            tags: [],
            applicationField: {
                id: null,
                label: "Application Field",
                newHint: false,
                identifier: null,
                hintFromOldKey: null,
                enHint: null,
                arHint: null,
                index: null,
                type: null,
                fieldData: {
                    id: null,
                    type: null,
                    spinnerObject: null,
                    applicationFieldTextValueString: null,
                    spinnerPrompt: null,
                    inputType: null,
                    retrieveSavedContact: false,
                    minNumber: null,
                    maxNumber: null,
                    minChar: null,
                    maxChar: null,
                    wrongInputHint: null,
                    radioSpanCount: null,
                    hint: null,
                    checkboxStylePopup: false,
                    dateFormat: "dd-MM-yyyy",
                    dependencies: [],
                }
            },
            applicationFieldTypes: null,
            applicationFieldInputTypes: ["text", "phone", "numeric", "decimal"],
            applicationFieldIdentifiers: null,
            applicationFields: [],
            receiptData: [],
            dataIdentifiers: [],
            dataDisplayNames: [],
            editApplicationField: false,
            applicationFieldId: null,
            allowAddApplicationField: false,
            createdApplication: null,
            createdApplicationField: null,
            showCreateMenuItem: false,
        }
    },
    watch:{
        showCreateMenuItem(){
            this.$emit('changeShowCreateMenuItem', this.showCreateMenuItem)
        },
        createdApplication: {
            deep: true,
            handler() {
                this.getApplicationFieldsByApplicationId();
                this.application.title = this.createdApplication.title
            }
        },
        selectedApplication: {
            deep: true,
            handler() {
                this.getApplicationFieldsByApplicationId();
                this.allowAddApplicationField = true
                this.application.title = this.selectedApplication.name
                this.application.id = this.selectedApplication.id
                this.showCreateMenuItem = true
            }
        },
        applicationFieldData: {
            deep: true,
            handler() {
                if (this.applicationFieldData.type != "text") {
                    this.applicationFieldData.inputType = null
                }
            }
        },
        payerReferenceData: {
            deep: true,
            handler() {
                if (this.fields['payerReference'].fieldData.type != "text") {
                    this.fields['payerReference'].fieldData.inputType = null
                }
            }
        },
        applicationFields: {
            deep: true,
            handler() {
                this.prepareReceiptData()
            }
        },
        applicationIsReference() {
            if (this.applicationIsReference && this.fields["payerReference"] == null) {
                this.fields["payerReference"] = {
                    "label": "Payer Reference",
                    "newHint": false,
                    "identifier": "payerReference",
                    "hintFromOldKey": null,
                    "enHint": "",
                    "arHint": "",
                    "index": "7",
                    "fieldData": {
                        type: null,
                        spinnerObject: null,
                        applicationFieldTextValueString: null,
                        spinnerPrompt: null,
                        inputType: null,
                        retrieveSavedContact: false,
                        minNumber: null,
                        maxNumber: null,
                        applicationFieldMinCharacter: null,
                        maxChar: null,
                        wrongInputHint: null,
                        radioSpanCount: null,
                        hint: null,
                        checkboxStylePopup: false,
                        dateFormat: "dd-MM-yyyy",
                        dependencies: [],
                    }
                }
            }
        },
        editApplicationField() {
            if (!this.editApplicationField) {
                this.applicationField = {
                    id: null,
                    label: "Application Field",
                    newHint: false,
                    identifier: null,
                    hintFromOldKey: null,
                    enHint: null,
                    arHint: null,
                    index: null,
                    type: null,
                    fieldData: {
                        id: null,
                        type: null,
                        spinnerObject: null,
                        applicationFieldTextValueString: null,
                        spinnerPrompt: null,
                        inputType: null,
                        retrieveSavedContact: false,
                        minNumber: null,
                        maxNumber: null,
                        minChar: null,
                        maxChar: null,
                        wrongInputHint: null,
                        radioSpanCount: null,
                        hint: null,
                        checkboxStylePopup: false,
                        dateFormat: "dd-MM-yyyy",
                        dependencies: [],
                    }
                }
            }
        }
    },
    created(){
        this.getStringFields("created");
        this.getTags("created");
        this.getApplicationFieldTypes("created");
        this.getApplicationFieldIdentifiers("created");
        this.getcollectionQuicqpayApplications("created");
    },
    methods: {
        getStringFields(source, forceGet) {
            if (source == 'created' && (forceGet || this.stringFields == null || this.stringFields.length == 0)) {
                Http()
                    .get(ENDPOINTS.stringFields, {
                    })
                    .then((response) => {
                        if (response.status) {
                            this.stringFields = response.data;
                            if (response.dialog != null && response.dialog.message != null) {
                                this.$toast.success(response.dialog.message)
                            }
                        } else {
                            if (response.dialog != null && response.dialog.message != null) {
                                this.$toast.error(response.dialog.message)
                            }
                        }
                    }).catch((e) => {
                        setTimeout(this.getStringFields(source, true), 500);
                    });
            }
            return this.stringFields;

        },
        getTags(source, forceGet) {
            if (source == 'created' && (forceGet || this.tags == null || this.tags.length == 0)) {
                Http()
                    .get(ENDPOINTS.tags, {
                    })
                    .then((response) => {
                        if (response.status) {
                            this.tags = response.data;
                            if (response.dialog != null && response.dialog.message != null) {
                                this.$toast.success(response.dialog.message)
                            }
                        } else {
                            if (response.dialog != null && response.dialog.message != null) {
                                this.$toast.error(response.dialog.message)
                            }
                        }
                    }).catch((e) => {
                        setTimeout(this.getTags(source, true), 500);
                    });
            }
            return this.tags;
        },
        getApplicationFieldTypes(source, forceGet) {
            if (source == 'created' && (forceGet || this.applicationFieldTypes == null || this.applicationFieldTypes.length == 0)) {
                Http()
                    .get(ENDPOINTS.applicationFieldTypes, {
                    })
                    .then((response) => {
                        if (response.status) {
                            this.applicationFieldTypes = response.data;
                            if (response.dialog != null && response.dialog.message != null) {
                                this.$toast.success(response.dialog.message)
                            }
                        } else {
                            if (response.dialog != null && response.dialog.message != null) {
                                this.$toast.error(response.dialog.message)
                            }
                        }
                    }).catch((e) => {
                        setTimeout(this.getApplicationFieldTypes(source, true), 500);
                    });
            }

        },
        getApplicationFieldIdentifiers(source, forceGet) {
            if (source == 'created' && (forceGet || this.applicationFieldIdentifiers == null || this.applicationFieldIdentifiers.length == 0)) {
                Http()
                    .get(ENDPOINTS.applicationFieldIdentifier, {
                    })
                    .then((response) => {
                        if (response.status) {
                            this.applicationFieldIdentifiers = response.data;
                            if (response.dialog != null && response.dialog.message != null) {
                                this.$toast.success(response.dialog.message)
                            }
                        } else {
                            if (response.dialog != null && response.dialog.message != null) {
                                this.$toast.error(response.dialog.message)
                            }
                        }
                    }).catch((e) => {
                        setTimeout(this.getApplicationFieldIdentifiers(source, true), 500);
                    });
            }
            return this.applicationFieldIdentifiers;
        },
        getApplicationFieldsByApplicationId() {
            if (this.createdApplication != null || this.selectedApplication != null) {
                Http()
                    .post(ENDPOINTS.applicationFieldsById, {
                        applicationId: this.createdApplication == null ? this.selectedApplication.id : this.createdApplication.id
                    })
                    .then((response) => {
                        if (response.status) {
                            this.applicationFields = response.data.sort((a, b) => a.index - b.index);
                            if (response.dialog != null && response.dialog.message != null) {
                                this.$toast.success(response.dialog.message)
                            }
                        } else {
                            if (response.dialog != null && response.dialog.message != null) {
                                this.$toast.error(response.dialog.message)
                            }
                        }
                    })
                    .catch((error) => {
                        this.$toast.error("Internal Server Error!")
                    })
            }
        },
        getcollectionQuicqpayApplications(source, forceGet) {
            if (source == 'created' && (forceGet || this.collectionQuicqpayApplications == null || this.collectionQuicqpayApplications.length == 0)) {
                Http()
                    .get(ENDPOINTS.collectionQuicqpayApplications, {
                    })
                    .then((response) => {
                        if (response.status) {
                            this.collectionQuicqpayApplications = response.data;
                            if (response.dialog != null && response.dialog.message != null) {
                                this.$toast.success(response.dialog.message)
                            }
                        } else {
                            if (response.dialog != null && response.dialog.message != null) {
                                this.$toast.error(response.dialog.message)
                            }
                        }
                    }).catch((e) => {
                        setTimeout(this.getcollectionQuicqpayApplications(source, true), 500);
                    });
            }
            return this.collectionQuicqpayApplications;
        },
        accountSearch() {
            Http()
                .post(ENDPOINTS.getAccount, {
                    accountId: this.applicationAccount.id
                })
                .then((response) => {
                    if (response.status) {
                        this.applicationAccount.name = response.data.name
                        if (response.dialog != null && response.dialog.message != null) {
                            this.$toast.success(response.dialog.message)
                        }
                    } else {
                        if (response.dialog != null && response.dialog.message != null) {
                            this.$toast.error(response.dialog.message)
                        }
                    }
                })
                .catch((error) => {
                    this.$toast.error("Internal Server Error!")
                })
        },
        createApplication() {
            if (!this.quicqpay){
                var invalidReference = false
                if (this.applicationIsReference) {
                    if (this.fields['payerReference'].fieldData.type == "text") {
                        if (this.fields['payerReference'].fieldData.inputType == "text") {
                            // invalidReference = this.checkIfEmpty(this. fields['payerReference'].fieldData.hint)
                        } else if (this.fields['payerReference'].fieldData.inputType == "phone") {
                            invalidReference = this.checkIfEmpty(this.fields['payerReference'].fieldData.retrieveSavedContact)
                        } else if (this.fields['payerReference'].fieldData.inputType == "numeric" || this.fields['payerReference'].fieldData.inputType == "decimal") {
                            invalidReference = this.checkIfEmpty(this.fields['payerReference'].fieldData.hint) || this.checkIfEmpty(this.fields['payerReference'].fieldData.minNumber) || this.checkIfEmpty(this.fields['payerReference'].fieldData.maxNumber) || this.checkIfEmpty(this.fields['payerReference'].fieldData.applicationFieldMinCharacter) || this.checkIfEmpty(this.fields['payerReference'].fieldData.maxChar) || this.checkIfEmpty(this.fields['payerReference'].fieldData.wrongInputHint)
                        }
                    } else if (this.fields['payerReference'].fieldData.type == "spinner" || this.fields['payerReference'].fieldData.type == "toggle" || this.fields['payerReference'].fieldData.type == "radio" || this.fields['payerReference'].fieldData.type == "checkbox") {
                        invalidReference = this.checkIfEmpty(this.fields['payerReference'].fieldData.spinnerObject)
                        if (this.fields['payerReference'].fieldData.type == "spinner") {
                            invalidReference = this.checkIfEmpty(this.fields['payerReference'].fieldData.spinnerPrompt)
                        } else if (this.fields['payerReference'].fieldData.type == "radio") {
                            invalidReference = this.checkIfEmpty(this.fields['payerReference'].fieldData.radioSpanCount)
                        } else if (this.fields['payerReference'].fieldData.type == "checkbox") {
                            invalidReference = this.checkIfEmpty(this.fields['payerReference'].fieldData.checkboxStylePopup)
                        }
                    }
                    if (invalidReference) {
                        this.$toast.error("Invalid Reference Type");
                        return;
                    }
                }

                for (const [key, field] of Object.entries(this.fields)) {
                    if (field != null) {
                        if (this.applicationSettlement != "Whish Money" && field.id == 'reasonId') {
                            continue;
                        }
                        if (!field.newHint && this.checkIfEmpty(field.hintFromOldKey)) {
                            if (!this.applicationIsReference && field.identifier == "payerReference") {
                                continue
                            }
                            this.$toast.error("Field " + field.label + " Has Invalid Value");
                            return;
                        }
                        if (field.newHint && (this.checkIfEmpty(field.arHint) || this.checkIfEmpty(field.enHint))) {
                            if (!this.applicationIsReference && field.identifier == "payerReference") {
                                continue
                            }
                            this.$toast.error("Field " + field.label + " Has Invalid Title");
                            return;
                        }
                    }
                }
                if (!this.applicationIsReference) {
                    this.fields["payerReference"] = null;
                }
                if (this.applicationSettlement != "Whish Money") {
                    this.fields["reasonId"] = null;
                }
            }
            if (this.checkIfEmpty(this.application.title) || this.checkIfEmpty(this.application.tags)) {
                this.$toast.error("Invalid Application Title Or Tags");
                return;
            } 
            Http()
                .post(ENDPOINTS.createApplication, {
                    title: this.application.title,
                    tags: this.application.tags.length != 0 ? this.application.tags.map((m) => m.id) : [],
                    settlement: this.applicationSettlement,
                    accountId: this.applicationAccount.id,
                    denominationId: this.denomination.id,
                    quicqpay: this.quicqpay,
                    ...this.fields
                })
                .then((response) => {
                    if (response.status) {
                        this.createdApplication = response.data
                        this.application.id = response.data.id
                        this.newApplication = false
                        this.allowAddApplicationField = true
                        this.showCreateMenuItem = true
                        this.$refs.closeApplicationModal.click()
                        if (response.dialog != null && response.dialog.message != null) {
                            this.$toast.success(response.dialog.message)
                        }
                    } else {
                        if (response.dialog != null && response.dialog.message != null) {
                            this.$toast.error(response.dialog.message)
                        }
                    }
                })
                .catch((error) => {
                    this.$toast.error("Internal Server Error!")
                })
        },
        updateApplicationField(field) {
            this.editApplicationField = true
            this.applicationField.id = field.id
            var jsonData = JSON.parse(field.data)
            var type = field.type
            this.newApplicationFieldTitle = false
            this.applicationField.hintFromOldKey = field.title
            this.applicationField.fieldData.type = type
            this.applicationField.identifier = field.identifier
            this.applicationField.index = field.index
            this.applicationField.fieldData.dependencies = jsonData.dependencies == undefined ? [] : jsonData.dependencies
            this.applicationField.fieldData.id = field.id

            if (type == "text") {
                this.applicationField.fieldData.hint = jsonData.hint
            } else if (type == "phone") {
                this.applicationField.fieldData.retrieveSavedContact = jsonData.retrieveSavedContact
            } else if (type == "numeric" || type == "decimal") {

                this.applicationField.fieldData.hint = jsonData.hint
                this.applicationField.fieldData.minNumber = jsonData.min
                this.applicationField.fieldData.maxNumber = jsonData.max
                this.applicationField.fieldData.minChar = jsonData.minchar
                this.applicationField.fieldData.maxChar = jsonData.maxchar
                this.applicationField.fieldData.wrongInputHint = jsonData.wrongInputHint
            } else if (type == 'spinner' || type == 'radio' || type == 'toggle' || type == 'checkbox') {
                this.applicationField.fieldData.spinnerPrompt = jsonData.prompt
                this.applicationField.fieldData.applicationFieldTextValueString = jsonData.objects.map(m => JSON.stringify(m))
                this.applicationField.fieldData.spinnerObject = jsonData.objects
                if (type == 'radio') {
                    this.applicationField.fieldData.radioSpanCount = jsonData.spanCount
                } else if (type == 'checkbox') {
                    this.applicationField.fieldData.radioSpanCount = jsonData.style == "popup"
                }
            } else if (type == 'date' || type == 'datetime') {
                this.applicationField.fieldData.dateFormat = jsonData.format
            }
        },
        createApplicationField() {
            if (this.checkIfEmpty(this.applicationField.fieldData.type)) {
                this.$toast.error("Type Has Invalid Value");
                return;
            }

            if (this.applicationField.newHint && (this.checkIfEmpty(this.applicationField.enHint) || this.checkIfEmpty(this.applicationField.arHint))) {
                this.$toast.error("New Titles Have Invalid Value");
                return;
            }

            if (!this.applicationField.newHint && this.checkIfEmpty(this.applicationField.hintFromOldKey)) {
                this.$toast.error("Title Has Invalid Value");
                return;
            }

            if (this.checkIfEmpty(this.applicationField.identifier)) {
                this.$toast.error("Identifier Has Invalid Value");
                return;
            }

            if (this.checkIfEmpty(this.applicationField.index)) {
                this.$toast.error("Index Has Invalid Value");
                return;
            }

            // if (this.applicationField.fieldData.type == "text" && this.checkIfEmpty(this.applicationField.fieldData.hint)) {
            //   this.$toast.error("Hint Has Invalid Value");
            //   return;
            // } 

            if (this.applicationField.fieldData.type == "numeric" || this.applicationField.fieldData.type == "numeric") {
                // if (this.checkIfEmpty(this.applicationField.fieldData.hint)) {
                //   this.$toast.error("Hint Has Invalid Value");
                //   return;
                // }
            }

            if (this.applicationField.fieldData.type == "spinner" || this.applicationField.fieldData.type == "toggle" || this.applicationField.fieldData.type == "radio" || this.applicationField.fieldData.type == "checkbox") {
                if (this.checkIfEmpty(this.applicationField.fieldData.spinnerObject)) {
                    this.$toast.error("Text With Values File Has Invalid Value");
                    return;
                }
                if (this.applicationField.fieldData == "spinner" && this.checkIfEmpty(this.applicationField.fieldData.spinnerPrompt)) {
                    this.$toast.error("Prompt Has Invalid Value");
                    return;
                }
                if (this.applicationField.fieldData == "radio" && this.checkIfEmpty(this.applicationField.fieldData.radioSpanCount)) {
                    this.$toast.error("Span Count Prompt Has Invalid Value");
                    return;
                }
            }

            if (this.createdApplication == null && this.selectedApplication == null) {
                this.$toast.error("Cannot Add Fields")
                return;
            } else {
                var appId = this.selectedApplication != null ? this.selectedApplication.id : this.createdApplication != null ? this.createdApplication.id : null
                Http()
                    .post(ENDPOINTS.createApplicationField, {
                        edit: this.editApplicationField,
                        fieldId: this.applicationField.id,
                        applicationId: appId,
                        ...this.applicationField
                    })
                    .then((response) => {
                        if (response.status) {
                            this.getApplicationFieldsByApplicationId()
                            this.createdApplicationField = response.data
                            this.newApplicationField = false
                            this.showCreateMenuItem = true
                            this.$refs.closeApplicationFieldModal.click()
                            this.applicationField = {
                                id: null,
                                label: "Application Field",
                                newHint: false,
                                identifier: null,
                                hintFromOldKey: null,
                                enHint: null,
                                arHint: null,
                                index: null,
                                type: null,
                                fieldData: {
                                    id: null,
                                    type: null,
                                    spinnerObject: null,
                                    applicationFieldTextValueString: null,
                                    spinnerPrompt: null,
                                    inputType: null,
                                    retrieveSavedContact: false,
                                    minNumber: null,
                                    maxNumber: null,
                                    minChar: null,
                                    maxChar: null,
                                    wrongInputHint: null,
                                    radioSpanCount: null,
                                    hint: null,
                                    checkboxStylePopup: false,
                                    dateFormat: "dd-MM-yyyy",
                                    dependencies: [],
                                }
                            }
                            if (response.dialog != null && response.dialog.message != null) {
                                this.$toast.success(response.dialog.message)
                            }
                        } else {
                            if (response.dialog != null && response.dialog.message != null) {
                                this.$toast.error(response.dialog.message)
                            }
                        }
                    })
                    .catch((error) => {
                        this.$toast.error("Internal Server Error!")
                    })
            }
        },
        prepareReceiptData(){
            this.receiptData = []
            this.applicationFields.forEach(field => {
                this.receiptData.push({
                    identifier: field.identifier,
                    displayName: null
                })
            });
            var amountIndex = this.receiptData.findIndex(f => f.identifier == "amount")
            if (amountIndex !== -1) {
                this.receiptData.splice(amountIndex, 0, {
                    identifier: "whishAccountID",
                    displayName: "Payer Account ID"
                }, {
                    identifier: "whishAccountName",
                    displayName: "Payer Account Name"
                });
            }
        },
        setReceiptData(){
            this.dataIdentifiers = this.receiptData.map(m => m.identifier)
            this.dataDisplayNames = this.receiptData.map(m => m.displayName)
            var appId = this.selectedApplication != null ? this.selectedApplication.id : this.createdApplication != null ? this.createdApplication.id : null
            if (appId == null){
                this.$toast.error("Something Went Wrong Please Contact The Team");
                return;
            }
            if (this.dataIdentifiers.length != this.dataDisplayNames.length){
                this.$toast.error("Please Fill All Fields");
                return;
            }
            Http()
                .post(ENDPOINTS.setApplicationReceiptData, {
                    dataIdentifiers: this.dataIdentifiers,
                    dataDisplayNames: this.dataDisplayNames,
                    applicationId: appId
                })
                .then((response) => {
                    if (response.status) {
                        this.$refs.closeReceiptDataModal.click()
                        if (response.dialog != null && response.dialog.message != null) {
                            this.$toast.success(response.dialog.message)
                        }
                    } else {
                        if (response.dialog != null && response.dialog.message != null) {
                            this.$toast.error(response.dialog.message)
                        }
                    }
                })
                .catch((error) => {
                    this.$toast.error("Internal Server Error!")
                })
        }
    }
}
</script>


<style scoped>

.subTitle{
  font-size: 23px;
  font-weight: 500;
}

.btn-primary{
  background-color: #E40046!important;
}

input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button {  

   opacity: 1;

}

</style>